
export const APP_CONFIG = {
  active_profile: 'prod', // test, prod,dev
  prod: {
    url_gateway: 'https://sgkyodow1b.execute-api.us-east-1.amazonaws.com/prod',
    url_hosted_ui:'https://soledad-prod.auth.us-east-1.amazoncognito.com/login?client_id=655k81lamn4jnud75f197vl4n6&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https%3A%2F%2Fadmin.municipiosoledad.gob.mx%2Fcallback',
    url_logout_hosted_ui:'https://soledad-prod.auth.us-east-1.amazoncognito.com/logout?client_id=655k81lamn4jnud75f197vl4n6&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https%3A%2F%2Fadmin.municipiosoledad.gob.mx%2Fcallback',
    accessKeyId:'AKIAQ4LW26TRUB6N7FYG',
    secretAccessKey: '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',
    bucket: 'ayto-online-prod'
  },
  test: {
    url_gateway: 'https://abj9nff3hj.execute-api.us-east-1.amazonaws.com/release',
    url_hosted_ui:'https://ayto-test.auth.us-east-1.amazoncognito.com/login?client_id=7obj7e393adjfm0c6mcduof9ur&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https%3A%2F%2Fweb.release.municipiosoledad.dosm14.com%2Fcallback',
    url_logout_hosted_ui:'https://ayto-test.auth.us-east-1.amazoncognito.com/logout?client_id=7obj7e393adjfm0c6mcduof9ur&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https%3A%2F%2Fweb.release.municipiosoledad.dosm14.com%2Fcallback',
    accessKeyId:'AKIAQ4LW26TRUB6N7FYG',
    secretAccessKey: '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',
    bucket: 'ayto-test'
  },
  dev: {
    url_gateway: '',
    url_hosted_ui:'https://ayto-test.auth.us-east-1.amazoncognito.com/login?client_id=3p14lsfl4rig9fg8hbrqv776br&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=http%3A%2F%2Flocalhost%3A8000%2Fcallback',
    url_logout_hosted_ui:'https://ayto-test.auth.us-east-1.amazoncognito.com/logout?client_id=3p14lsfl4rig9fg8hbrqv776br&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=http%3A%2F%2Flocalhost%3A8000%2Fcallback',
    accessKeyId:'AKIAQ4LW26TRUB6N7FYG',
    secretAccessKey: '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',
    bucket: 'ayto-test'
  }
};
