import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppUtils } from '@global/helpers/app.utils';
import { SnackBarService } from '@global/helpers/snack-bar.service';

@Injectable({ providedIn: 'root' })
export class RouterGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private snackBarService: SnackBarService) { }

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = AppUtils.currentUser();

    //console.log("currentUser",currentUser)
    if (currentUser) {
      return true;
    }

    // not logged in so redirect to login page with the return url
   // this._router.navigate(['login']);
    AppUtils.initHostedUi();
    return false;
  }
}
