import { ModuleConfig,ModuleFuryConfig } from '@quiox/global/config/config';

export const registroContribuyenteMenu: ModuleFuryConfig = {
    name: 'Contribuyente',
    menu: [
        {
            name: 'Contribuyente',
            position: 400,
            type: 'subheading',
            customClass: 'first-subheading',
            roles: [
                'ROLE_VER_CONTRIBUYENTE',
                'ROLE_GESTIONAR_CONTRIBUYENTE',
                'ROLE_VER_TIPO_IDENTIFICACION',
                'ROLE_GESTIONAR_TIPO_IDENTIFICACION'
            ]
        },
                {
                    name: 'Contribuyente',
                    routeOrFunction: '/webapp/rec/contribuyente',
                    position: 405,
                    icon: 'transfer_within_a_station',
                    roles: [
                        'ROLE_LISTAR_CONTRIBUYENTE',
                    ]
                },
                {
                    name: 'Tipo Identificación',
                    routeOrFunction: '/webapp/rec/tipo-identificacion',
                    position: 410,
                    icon: 'featured_play_list',
                    roles: [
                        'ROLE_VER_TIPO_IDENTIFICACION',
                        'ROLE_GESTIONAR_TIPO_IDENTIFICACION'
                    ]
                }
            ]
};
