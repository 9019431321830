import { administracionMenu } from '../administracion/config/config';
import { durbanoMenu } from '../durbano/config/config';
//import { catastroMenu } from '../catastro/config/config';
import { generalMenu } from '../general/config/config';
import { rrhhMenu } from '../rrhh/config/config';
import { cajasMenu } from '../cajas/config/config';
import { tesoreriaMenu } from '../tesoreria/config/config';
import { registroContribuyenteMenu } from '../registro-contribuyente/config/config';
import { notariasMenu } from '../notarias/config/config';
import { tramitesMenu } from '../tramites/config/config';
import { solicitudesMenu } from '../solicitudes/config/config';
import { transitoMenu } from '../transito/config/config';
import { administracionOnlineMenu } from '../administracion-online/config/config';
import { comercioMenu } from '../comercio/config/config';
import { proteccionMenu } from '../proteccion/config/config';
import { ecologiaMenu } from '../ecologia/config/config';
import { fiscalizacionMenu } from '../fiscalizacion/config/config';
import { smMenu } from '../sm/config/config';
import {consultorioMenu} from '../consultorio/config/config';

export const configCustom = {
    projectName: 'H. Ayuntamiento de SGS',
    modules: [
        //administracionMenu,
        //administracionOnlineMenu,
        //catastroMenu,
        //generalMenu,
        //durbanoMenu,
        //rrhhMenu,
        //cajasMenu,
        //tesoreriaMenu,
        transitoMenu,
        registroContribuyenteMenu,
        notariasMenu,
        tramitesMenu,
        //solicitudesMenu,
        comercioMenu,
        proteccionMenu,
        ecologiaMenu,
        //fiscalizacionMenu,
        smMenu,
        consultorioMenu
    ]
};
