import { ModuleConfig, ModuleFuryConfig } from '@quiox/global/config/config';

export const proteccionMenu: ModuleFuryConfig = {
  name: 'Protección',
  menu: [
    {
      name: 'Protección ',
      position: 1,
      type: 'subheading',
      customClass: 'first-subheading',
      roles: [
        'ROLE_LISTAR_GIRO_IMPACTO',
      ]
    },
    {
      name: 'Panel de Control ',
      routeOrFunction: '/webapp/proteccion/tramites/panel-control',
      icon: 'group',
      position: 2,
      roles: ['ROLE_VER_PANEL_CONTROL_PROTECCION']
    },
   
    
    {
      name: 'TRÁMITES',
      position: 20,
      icon: 'receipt_long',
      roles: [
        'ROLE_VER_TRAMITE_PROTECCION_INICIO',
        'ROLE_VER_TRAMITE_PROTECCION_GENERAL',
        'ROLE_VER_TRAMITE_PROTECCION_REFRENDO'
      ],
      subItems: [
        // {
        //   name: 'Todos los Trámites',
        //   routeOrFunction: '/webapp/proteccion/tramites/general',
        //   icon: 'group',
        //   position: 18,
        //   roles: ['ROLE_VER_TRAMITE_PROTECCION_GENERAL']
        // },
        {
          name: 'Inicio',
          routeOrFunction: '/webapp/proteccion/tramites/inicio',
          icon: 'group',
          position: 21,
          roles: ['ROLE_VER_TRAMITE_PROTECCION_INICIO']
        },
        {
          name: 'Refrendo',
          routeOrFunction: '/webapp/proteccion/tramites/refrendo',
          icon: 'group',
          position: 22,
          roles: ['ROLE_VER_TRAMITE_PROTECCION_REFRENDO']
        },
          ]
    },
    {
      name: 'MANTENIMIENTO',
      position: 23,
      icon: 'miscellaneous_services',
      roles: [
        'ROLE_LISTAR_DICTAMEN_PROTECCION',
      ],
      subItems: [

        {
          name: 'Resumen Dictamen',
          routeOrFunction: '/webapp/proteccion/dictamen',
          icon: 'group',
          position:24,
          roles: ['ROLE_LISTAR_DICTAMEN_PROTECCION']
        },    
      ]
    },
    {
      name: 'CATÁLOGO',
      position: 40,
      icon: 'auto_stories',
      roles: [
        'ROLE_LISTAR_RANGO_GIRO_SEGURIDAD',
        'ROLE_VER_TIPO_DOCUMENTO',
        ],
      subItems: [

    {
      name: 'Rango de Giro',
      routeOrFunction: '/webapp/proteccion/rango-giro',
      icon: 'group',
      position: 42,
      roles: ['ROLE_LISTAR_RANGO_GIRO_SEGURIDAD']
    },
        {
          name: 'Tipo Documento',
          routeOrFunction: '/webapp/proteccion/tipo-documento',
          icon: 'group',
          position: 45,
          roles: ['ROLE_VER_TIPO_DOCUMENTO']
        },
      ]
    },

    {
      name: 'CONFIGURACIÓN',
      position: 50,
      icon: 'settings',
      roles: [
        'ROLE_VER_USUARIOS_AREA_TRAMITE_PROTECCION',
        'ROLE_VER_ESTADOS_AREA_TRAMITE_PROTECCION',
        'ROLE_VER_CONFIGURACION_TRAMITE',
        'ROLE_VER_CONFIGURACION_GENERAL',
        'ROLE_LISTAR_GIRO_RANGO_GIRO_SEGURIDAD'
      ],
      subItems: [
        {
          name: 'Config. Giro',
          routeOrFunction: '/webapp/proteccion/config-rango-giro',
          icon: 'group',
          position: 10,
          roles: ['ROLE_LISTAR_GIRO_RANGO_GIRO_SEGURIDAD']
        },
        {
          name: 'Usuarios/Áreas',
          routeOrFunction: '/webapp/proteccion/tramites/configuracion-usuarios',
          icon: 'group',
          position: 52,
          roles: [
            'ROLE_VER_USUARIOS_AREA_TRAMITE_PROTECCION',
          ]
        },
        {
          name: 'Área-Estados',
          routeOrFunction: '/webapp/proteccion/tramites/configuracion-estados',
          icon: 'group',
          position: 55,
          roles: [
            'ROLE_VER_ESTADOS_AREA_TRAMITE_PROTECCION',
          ]
        },
        {
          name: 'Configuración Trámites',
          routeOrFunction: '/webapp/proteccion/tramites/configuracion-tramite',
          icon: 'group',
          position: 60,
          roles: [
            'ROLE_VER_CONFIGURACION_TRAMITE_PROTECCION',
          ]
        },
        {
          name: 'Config Doc-General',
          routeOrFunction: '/webapp/proteccion/tramites/doc-general',
          icon: 'group',
          position: 65,
          roles: [
            'ROLE_VER_CONFIGURACION_TRAMITE_PROTECCION',
          ]
        },
        {
          name: 'Config Doc-Arrendado',
          routeOrFunction: '/webapp/proteccion/tramites/doc-arrendado',
          icon: 'group',
          position: 70,
          roles: [
            'ROLE_VER_CONFIGURACION_TRAMITE_PROTECCION',
          ]
        },
        {
          name: 'Configuración General',
          routeOrFunction: '/webapp/proteccion/configuracion-general/1',
          icon: 'group',
          position: 75,
          roles: [
            'ROLE_VER_CONFIGURACION_GENERAL',
          ]
        },
      ]
    }
  ]
};
