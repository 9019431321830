import {ModuleConfig, ModuleFuryConfig} from '@quiox/global/config/config';

export const consultorioMenu: ModuleFuryConfig = {
    name: 'Consultorios',
    menu: [
        {
            name: 'Consultorios',
            position: 1,
            type: 'subheading',
            customClass: 'first-subheading',
            roles: [
                'ROLE_MASTER',
                'ROLE_VER_PACIENTE_CONSULTORIO',
                'ROLE_VER_CONSULTA_CONSULTORIO',
                'ROLE_VER_CONSULTA_CONSULTORIO_MEDICO',
                'ROLE_VER_CONSULTORIO',
                'ROLE_GESTIONAR_CONSULTORIO',
            ],
        },
        {
            name: 'ADMINISTRACIÓN',
            position: 2,
            icon: 'medical_information',
            roles: [
                'ROLE_MASTER',
                'ROLE_VER_CONSULTA_CONSULTORIO'
            ],
                subItems: [
            {
                name: 'Consulta',
                routeOrFunction: '/webapp/consultorio/admision/consulta',
                icon: 'medical_services',
                position: 4,
                roles: [
                    'ROLE_MASTER',
                    'ROLE_VER_CONSULTA_CONSULTORIO'
                ]
            },
            ],
        },
        {
            name: 'MÉDICO',
            position: 4,
            icon: 'medical_services',
            roles: [
                'ROLE_MASTER',
                'ROLE_VER_CONSULTA_CONSULTORIO_MEDICO',
            ],
            subItems: [
            {
                name: 'Paciente',
                routeOrFunction: '/webapp/consultorio/medico/paciente',
                icon: 'group',
                position: 2,
                roles: [
                    'ROLE_MASTER',
                    'ROLE_VER_PACIENTE_CONSULTORIO'
                ]
            },
            {
                name: 'Consultas',
                routeOrFunction: '/webapp/consultorio/medico/consultas',
                icon: 'medical_services',
                position: 5,
                roles: [
                    'ROLE_MASTER',
                    'ROLE_VER_CONSULTA_CONSULTORIO_MEDICO'
                ]
            },
            ]
        },
        {
            name: 'REPORTES',
            position: 10,
            icon: 'grading',
            roles: [
                'ROLE_IMPRIMIR_CONSULTA_CONSULTORIO',
                'ROLE_MASTER'
            ],
            subItems: [
                {
                    name: 'Consultas',
                    routeOrFunction: '/webapp/consultorio/reportes/consultas',
                    position: 104,
                    roles: ['ROLE_IMPRIMIR_CONSULTA_CONSULTORIO', 'ROLE_MASTER']
                },
            ]
        },
        {
            name: 'CONFIGURACIÓN',
            position: 11,
            icon: 'settings',
            roles: [
                'ROLE_MASTER',
                'ROLE_VER_CONSULTORIO',
                'ROLE_GESTIONAR_CONSULTORIO',
            ],
            subItems: [
                {
                    name: 'Consultorios',
                    routeOrFunction: '/webapp/consultorio/institucion/configuracion/consultorio-institucion',
                    position: 111,
                    roles: [
                        'ROLE_VER_CONSULTORIO',
                        'ROLE_GESTIONAR_CONSULTORIO'
                    ]
                },
                {
                    name: 'Medicamentos',
                    routeOrFunction: '/webapp/consultorio/institucion/configuracion/medicamento',
                    position: 112,
                    roles: [
                        'ROLE_VER_MEDICAMENTO_CONSULTORIO',
                        'ROLE_GESTIONAR_MEDICAMENTO_CONSULTORIO'
                    ]
                },
                {
                    name: 'Diagnóstico',
                    routeOrFunction: '/webapp/consultorio/institucion/configuracion/diagnostico',
                    position: 113,
                    roles: [
                        'ROLE_VER_DIAGNOSTICO_CONSULTA_CONSULTORIO',
                        'ROLE_GESTIONAR_DIAGNOSTICO_CONSULTA_CONSULTORIO'
                    ]
                },
                {
                    name: 'Farmacia',
                    routeOrFunction: '/webapp/consultorio/institucion/farmacia',
                    position: 114,
                    roles: [
                        'ROLE_VER_FARMACIA_CONSULTORIO',
                        'ROLE_LISTAR_FARMACIA_CONSULTORIO'
                    ]
                },
                {
                    name: 'Motivos de Cancelación',
                    routeOrFunction: '/webapp/consultorio/institucion/configuracion/motivo-cancelacion',
                    position: 117,
                    roles: [
                        'ROLE_VER_MOTIVO_CANCELACION_CONSULTORIO',
                        'ROLE_GESTIONAR_MOTIVO_CANCELACION_CONSULTORIO'
                    ]
                },
            ]
        }


    ]
};
