import {ModuleConfig, ModuleFuryConfig} from '@quiox/global/config/config';

export const tramitesMenu: ModuleFuryConfig = {
        name: 'Trámites',
        menu: [
            {
                name: 'Trámites',
                position: 415,
                type: 'subheading',
                customClass: 'first-subheading',
                roles: [
                    'ROLE_MASTER',
                    'ROLE_VER_PANEL_CONTROL_TRAMITE',
                    'ROLE_VER_CATASTRO_ALTA_DIVISION_PREDIO',
                    'ROLE_GESTIONAR_CATASTRO_ALTA_DIVISION_PREDIO',
                    'ROLE_GESTIONAR_CATASTRO_ALTA_POR_REGIMEN_EN_CONDOMINIO',
                    'ROLE_VER_CATASTRO_ALTA_POR_REGIMEN_EN_CONDOMINIO',
                    'ROLE_GESTIONAR_CATASTRO_ALTA_POR_FRACCIONAMIENTO',
                    'ROLE_VER_CATASTRO_ALTA_POR_FRACCIONAMIENTO',
                    'ROLE_GESTIONAR_CATASTRO_ALTA_POR_FUSION',
                    'ROLE_VER_CATASTRO_ALTA_POR_FUSION',
                    'ROLE_GESTIONAR_CATASTRO_ALTA_PADRON_CATASTRAL',
                    'ROLE_VER_CATASTRO_ALTA_PADRON_CATASTRAL',
                    'ROLE_GESTIONAR_CATASTRO_CERTIFICADO_SI_NO_PROPIEDAD',
                    'ROLE_VER_CATASTRO_CERTIFICADO_SI_NO_PROPIEDAD',
                    'ROLE_GESTIONAR_CATASTRO_RECTIFICACION_NOMBRE_PROPIETARIO',
                    'ROLE_VER_CATASTRO_RECTIFICACION_NOMBRE_PROPIETARIO',
                    'ROLE_GESTIONAR_CATASTRO_CERTIFICACION_MEDIDAS_COLINDANCIAS',
                    'ROLE_VER_CATASTRO_CERTIFICACION_MEDIDAS_COLINDANCIAS',
                    'ROLE_GESTIONAR_CATASTRO_RECTIFICACION_SUPERCIE_TERRENO',
                    'ROLE_VER_CATASTRO_RECTIFICACION_SUPERCIE_TERRENO',
                    'ROLE_GESTIONAR_CATASTRO_RECTIFICACION_UBICACION',
                    'ROLE_VER_CATASTRO_RECTIFICACION_UBICACION',
                    'ROLE_GESTIONAR_TRAMITE_CONTRIBUYENTE',
                    'ROLE_VER_ALTA_CONTRIBUYENTE',
                    'ROLE_VER_ACTUALIZAR_CONTRIBUYENTE',
                    'ROLE_GESTIONAR_ACTUALIZAR_CONTRIBUYENTE',
                    'ROLE_GESTIONAR_AVALUO',
                    'ROLE_VER_AVALUO',
                    'ROLE_GESTIONAR_TRASLADO_DOMINIO',
                    'ROLE_VER_TRASLADO_DOMINIO'

                ],
            },
            {
                name: 'Panel de Control',
                routeOrFunction: '/webapp/tramites/panel-control-tramite',
                position: 420,
                icon: 'tab',
                roles: [
                    'ROLE_VER_PANEL_CONTROL_TRAMITE'
                ]
            },
            {
                name: 'Contribuyente',
                routeOrFunction: '/webapp/tramites/contribuyente-general',
                position: 421,
                icon: 'transfer_within_a_station',
                roles: [
                    'ROLE_GESTIONAR_TRAMITE_CONTRIBUYENTE',
                    'ROLE_VER_TRAMITE_CONTRIBUYENTE'
                ]
            },
            /* {
                name: 'Alta Contribuyente',
                routeOrFunction: '/webapp/tramites/alta-contribuyente',
                position: 421,
                roles: [
                    'ROLE_GESTIONAR_TRAMITE_CONTRIBUYENTE',
                    'ROLE_VER_ALTA_CONTRIBUYENTE'
                ]
            },
            {
                name: 'Actualizar Contribuyente',
                routeOrFunction: '/webapp/tramites/actualizar-contribuyente',
                position: 422,
                roles: [
                    'ROLE_VER_ACTUALIZAR_CONTRIBUYENTE',
                    'ROLE_GESTIONAR_ACTUALIZAR_CONTRIBUYENTE'
                ]
            }, */
    {
        name: 'CATASTRO',
        position: 425,
        icon: 'description',
        roles: [
            'ROLE_MASTER1',
            'ROLE_VER_CATASTRO_ALTA_DIVISION_PREDIO',
            'ROLE_GESTIONAR_CATASTRO_ALTA_DIVISION_PREDIO',
            'ROLE_GESTIONAR_CATASTRO_ALTA_POR_REGIMEN_EN_CONDOMINIO',
            'ROLE_VER_CATASTRO_ALTA_POR_REGIMEN_EN_CONDOMINIO',
            'ROLE_GESTIONAR_CATASTRO_ALTA_POR_FRACCIONAMIENTO',
            'ROLE_VER_CATASTRO_ALTA_POR_FRACCIONAMIENTO',
            'ROLE_GESTIONAR_CATASTRO_ALTA_POR_FUSION',
            'ROLE_VER_CATASTRO_ALTA_POR_FUSION',
            'ROLE_GESTIONAR_CATASTRO_ALTA_PADRON_CATASTRAL',
            'ROLE_VER_CATASTRO_ALTA_PADRON_CATASTRAL',
            'ROLE_GESTIONAR_CATASTRO_CERTIFICADO_SI_NO_PROPIEDAD',
            'ROLE_VER_CATASTRO_CERTIFICADO_SI_NO_PROPIEDAD',
            'ROLE_GESTIONAR_CATASTRO_RECTIFICACION_NOMBRE_PROPIETARIO',
            'ROLE_VER_CATASTRO_RECTIFICACION_NOMBRE_PROPIETARIO',
            'ROLE_GESTIONAR_CATASTRO_CERTIFICACION_MEDIDAS_COLINDANCIAS',
            'ROLE_VER_CATASTRO_CERTIFICACION_MEDIDAS_COLINDANCIAS',
            'ROLE_GESTIONAR_CATASTRO_RECTIFICACION_SUPERCIE_TERRENO',
            'ROLE_VER_CATASTRO_RECTIFICACION_SUPERCIE_TERRENO',
            'ROLE_GESTIONAR_CATASTRO_RECTIFICACION_UBICACION',
            'ROLE_VER_CATASTRO_RECTIFICACION_UBICACION',
            'ROLE_GESTIONAR_AVALUO',
            'ROLE_VER_AVALUO',
        ],
        subItems: [
            {
                name: 'Certificación',
                routeOrFunction: '/webapp/tramites/catastro/certificacion',
                position: 430,
                roles: [
                    'ROLE_MASTER1'
                ]
            },
            {
                name: 'Empadronamiento',
                routeOrFunction: '/webapp/tramites/catastro/alta-padron-catastral',
                position: 435,
                roles: [
                    'ROLE_GESTIONAR_CATASTRO_ALTA_PADRON_CATASTRAL',
                    'ROLE_VER_CATASTRO_ALTA_PADRON_CATASTRAL'
                ]
            },
            {
                name: 'División de Predio',
                routeOrFunction: '/webapp/tramites/catastro/alta-division-predio',
                position: 440,
                roles: [
                    'ROLE_VER_CATASTRO_ALTA_DIVISION_PREDIO',
                    'ROLE_GESTIONAR_CATASTRO_ALTA_DIVISION_PREDIO'
                ]
            },
            {
                name: 'Fusión de Predio',
                routeOrFunction: '/webapp/tramites/catastro/alta-por-fusion',
                position: 445,
                roles: [
                    'ROLE_GESTIONAR_CATASTRO_ALTA_POR_FUSION',
                    'ROLE_VER_CATASTRO_ALTA_POR_FUSION'
                ]
            },
            {
                name: 'Alta Rég. Condominio',
                routeOrFunction: '/webapp/tramites/catastro/alta-por-regimen-en-condominio',
                position: 450,
                roles: [
                    'ROLE_GESTIONAR_CATASTRO_ALTA_POR_REGIMEN_EN_CONDOMINIO',
                    'ROLE_VER_CATASTRO_ALTA_POR_REGIMEN_EN_CONDOMINIO'
                ]
            },
            {
                name: 'Alta Fraccionamiento',
                routeOrFunction: '/webapp/tramites/catastro/alta-por-fraccionamiento',
                position: 455,
                roles: [
                    'ROLE_GESTIONAR_CATASTRO_ALTA_POR_FRACCIONAMIENTO',
                    'ROLE_VER_CATASTRO_ALTA_POR_FRACCIONAMIENTO'
                ]
            },
            {
                name: 'Certificar Med. y Col.',
                routeOrFunction: '/webapp/tramites/catastro/certificacion-medidas-colindancias',
                position: 460,
                roles: [
                    'ROLE_GESTIONAR_CATASTRO_CERTIFICACION_MEDIDAS_COLINDANCIAS',
                    'ROLE_VER_CATASTRO_CERTIFICACION_MEDIDAS_COLINDANCIAS'
                ]
            },
            {
                name: 'Rectificar Sup. Terr.',
                routeOrFunction: '/webapp/tramites/catastro/rectificacion-superficie-terreno',
                position: 465,
                roles: [
                    'ROLE_GESTIONAR_CATASTRO_RECTIFICACION_SUPERCIE_TERRENO',
                    'ROLE_VER_CATASTRO_RECTIFICACION_SUPERCIE_TERRENO'
                ]
            },
            {
                name: 'Rectificar Ubicación',
                routeOrFunction: '/webapp/tramites/catastro/rectificacion-ubicacion',
                position: 470,
                roles: [
                    'ROLE_GESTIONAR_CATASTRO_RECTIFICACION_UBICACION',
                    'ROLE_VER_CATASTRO_RECTIFICACION_UBICACION'
                ]
            },

            {
                name: 'Sí/No Propiedad',
                routeOrFunction: '/webapp/tramites/catastro/certificado-si-no-propiedad',
                position: 475,
                roles: [
                    'ROLE_GESTIONAR_CATASTRO_CERTIFICADO_SI_NO_PROPIEDAD',
                    'ROLE_VER_CATASTRO_CERTIFICADO_SI_NO_PROPIEDAD'
                ]
            },
            {
                name: 'Rectificar Nomb. Prop.',
                routeOrFunction: '/webapp/tramites/catastro/rectificacion-nombre-propietario',
                position: 480,
                roles: [
                    'ROLE_GESTIONAR_CATASTRO_RECTIFICACION_NOMBRE_PROPIETARIO',
                    'ROLE_VER_CATASTRO_RECTIFICACION_NOMBRE_PROPIETARIO'
                ]
            },
            {
                name: 'Avalúo.',
                routeOrFunction: '/webapp/tramites/catastro/avaluo',
                position: 481,
                roles: [
                    'ROLE_GESTIONAR_AVALUO',
                    'ROLE_VER_AVALUO'
                ]
            },
            {
                name: 'Traslado Dominio.',
                routeOrFunction: '/webapp/tramites/catastro/traslado-dominio',
                position: 481,
                roles: [
                    'ROLE_GESTIONAR_TRASLADO_DOMINIO',
                    'ROLE_VER_TRASLADO_DOMINIO'
                ]
            },
        ]
    },
    {
        name: 'CONFIGURACIÓN',
        position: 485,
        icon: 'settings',
        roles: [
            'ROLE_MASTER',
            'ROLE_GESTIONAR_TIPO_DOCUMENTO_TRAMITE',
            'ROLE_VER_TIPO_DOCUMENTO_TRAMITE'

        ],
        subItems: [

            /*  {
               name: 'Config. Impresión',
               routeOrFunction: '/webapp/tramites/configuracion-impresion',
               position: 490,
               roles: [
                   'ROLE_MASTER',
               ]
           }, */
            {
                name: 'Configuración Trámite General',
                routeOrFunction: '/webapp/tramites/configurar-tramite-general',
                position: 486,
                roles: [
                    'ROLE_MASTER',
                ]
            },
            {
                name: 'Modalidad Trámite',
                routeOrFunction: '/webapp/tramites/modalidad-tramite',
                position: 495,
                roles: [
                    'ROLE_MASTER',
                ]
            },
            {
                name: 'Configuración Trámite',
                routeOrFunction: '/webapp/tramites/configuracion-tramite',
                position: 500,
                roles: [
                    'ROLE_MASTER',
                ]
            },

            {
                name: 'Tipo de Documento',
                routeOrFunction: '/webapp/tramites/tipo-documento-tramite',
                position: 501,
                roles: [
                    'ROLE_GESTIONAR_TIPO_DOCUMENTO_TRAMITE', 'ROLE_VER_TIPO_DOCUMENTO_TRAMITE'
                ]
            },
            {
                name: 'Usuarios/Áreas',
                routeOrFunction: '/webapp/tramites/configuracion-usuarios',
                position: 505,
                roles: [
                    'ROLE_MASTER',
                ]
            },
            {
                name: 'Área-Estados',
                routeOrFunction: '/webapp/tramites/configuracion-estados',
                position: 510,
                roles: [
                    'ROLE_MASTER',
                ]
            }
        ]
    },
]
}
;
