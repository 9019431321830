import { ModuleConfig, ModuleFuryConfig } from '@quiox/global/config/config';

export const comercioMenu: ModuleFuryConfig = {
  name: 'Comercio',
  menu: [   
    {
      name: 'Comercio',
      position: 1,
      type: 'subheading',
      customClass: 'first-subheading',
      roles: [
        'ROLE_LISTAR_COMERCIO_R',
      ]
    },
    {
      name: 'Panel de Control ',
      routeOrFunction: '/webapp/comercio/tramites/panel-control',
      icon: 'group',
      position: 2,
      roles: ['ROLE_VER_PANEL_CONTROL_COMERCIO']
    },
    {
      name: 'Comercio',
      routeOrFunction: '/webapp/comercio/comercio',
      icon: 'group',
      position: 3,
      roles: ['ROLE_LISTAR_COMERCIO_R']
    },
    {
      name: 'Publicidad',
      routeOrFunction: '/webapp/comercio/publicidad',
      icon: 'group',
      position: 4,
      roles: ['ROLE_LISTAR_PUBLICIDAD_R']
    },
    {
      name: 'MANTENIMIENTO',
      position: 5,
      icon: 'miscellaneous_services',
      roles: [
        'ROLE_LISTAR_COMERCIO',
      ],
      subItems: [

    {
      name: 'Comercio',
      routeOrFunction: '/webapp/comercio/mantenimiento/comercio',
      icon: 'group',
      position: 7,
      roles: ['ROLE_LISTAR_COMERCIO']
    },
    {
      name: 'Publicidad',
      routeOrFunction: '/webapp/comercio/mantenimiento/publicidad',
      icon: 'group',
      position: 10,
      roles: ['ROLE_LISTAR_PUBLICIDAD']
    },
      ]
    },
    {
      name: 'REPORTE',
      position: 11,
      icon: 'text_snippet',
      roles: [
        'ROLE_VER_REPORTE_RECAUDACION_TRAMITE_COMERCIO',
      ],
      subItems: [
        {
          name: 'Recaudación por Trámites',
          routeOrFunction: '/webapp/comercio/reporte',
          icon: 'group',
          position: 12,
          roles: ['ROLE_VER_REPORTE_RECAUDACION_TRAMITE_COMERCIO']
        },
        {
          name: 'Mapa de Comercios',
          routeOrFunction: '/webapp/comercio/mapa',
          icon: 'group',
          position: 13,
          roles: ['ROLE_VER_MAPA_COMERCIO']
        },
      ]
    },
     {
      name: 'TRÁMITES',
      position: 14,
       icon: 'receipt_long',
      roles: [
        'ROLE_VER_TRAMITE_COMERCIO_INICIO',
        'ROLE_VER_TRAMITE_COMERCIO_REFRENDO',
        'ROLE_VER_TRAMITE_COMERCIO_CAMBIO_DIRECCION',
        'ROLE_VER_TRAMITE_COMERCIO_CAMBIO_GIRO',
        'ROLE_VER_TRAMITE_COMERCIO_GENERAL',
        'ROLE_VER_PANEL_CONTROL_COMERCIO',
      ],
       subItems: [   
    {
      name: 'Todos los Trámites',
      routeOrFunction: '/webapp/comercio/tramites/general',
      icon: 'group',
      position: 18,
      roles: ['ROLE_VER_TRAMITE_COMERCIO_GENERAL']
    },
    {
      name: 'L. Funcionamiento Inicio',
      routeOrFunction: '/webapp/comercio/tramites/inicio',
      icon: 'group',
      position: 20,
      roles: ['ROLE_VER_TRAMITE_COMERCIO_INICIO']
    },
    {
      name: 'L. Funcionamiento Refrendo',
      routeOrFunction: '/webapp/comercio/tramites/refrendo',
      icon: 'group',
      position: 22,
      roles: ['ROLE_VER_TRAMITE_COMERCIO_REFRENDO']
    },
    {
      name: 'Cambio de Direc. y Superficie',
      routeOrFunction: '/webapp/comercio/tramites/cambio-direccion',
      icon: 'group',
      position: 24,
      roles: ['ROLE_VER_TRAMITE_COMERCIO_CAMBIO_DIRECCION']
    },
    {
      name: 'Cambio de Superficie',
      routeOrFunction: '/webapp/comercio/tramites/cambio-superficie',
      icon: 'group',
      position: 25,
      roles: ['ROLE_VER_TRAMITE_COMERCIO_CAMBIO_SUPERFICIE']
    },
    {
      name: 'Cambio de Giro',
      routeOrFunction: '/webapp/comercio/tramites/cambio-giro',
      icon: 'group',
      position: 26,
      roles: ['ROLE_VER_TRAMITE_COMERCIO_CAMBIO_GIRO']
    },
    {
      name: 'Cambio de Razón Social',
      routeOrFunction: '/webapp/comercio/tramites/cambio-razon-social',
      icon: 'group',
      position: 28,
      roles: ['ROLE_VER_TRAMITE_COMERCIO_CAMBIO_RAZON_SOCIAL']
    },
    {
      name: 'Reposición',
      routeOrFunction: '/webapp/comercio/tramites/reposicion',
      icon: 'group',
      position: 30,
      roles: ['ROLE_VER_TRAMITE_COMERCIO_REPOSICION']
    },
    {
      name: 'Baja',
      routeOrFunction: '/webapp/comercio/tramites/baja',
      icon: 'group',
      position: 32,
      roles: ['ROLE_VER_TRAMITE_COMERCIO_BAJA']
    },
    {
      name: 'Reactivación',
      routeOrFunction: '/webapp/comercio/tramites/reactivacion',
      icon: 'group',
      position: 33,
      roles: ['ROLE_VER_TRAMITE_COMERCIO_REACTIVACION']
    },
    {
      name: 'Publicidad',
      routeOrFunction: '/webapp/comercio/tramites/publicidad',
      icon: 'group',
      position: 34,
      roles: ['ROLE_VER_TRAMITE_COMERCIO_SOLICITUD_PUBLICIDAD']
    },
    {
      name: 'Baja Publicidad',
      routeOrFunction: '/webapp/comercio/tramites/baja-publicidad',
      icon: 'group',
      position: 34,
      roles: ['ROLE_VER_TRAMITE_COMERCIO_BAJA_PUBLICIDAD']
    },
           ]
     },

    {
      name: 'CATÁLOGO',
      position: 35,
      icon: 'auto_stories',
      roles: [
        'ROLE_LISTAR_GIRO',
        'ROLE_LISTAR_RANGO_GIRO',
        'ROLE_LISTAR_TIPO_PUBLICIDAD',
        'ROLE_LISTAR_RANGO_PUBLICIDAD',

      ],
      subItems: [

    {
      name: 'Giros',
      routeOrFunction: '/webapp/comercio/giro',
      icon: 'group',
      position: 36,
      roles: ['ROLE_LISTAR_GIRO']
    },
    {
      name: 'Rango Giro',
      routeOrFunction: '/webapp/comercio/rango-giro',
      icon: 'group',
      position: 40,
      roles: ['ROLE_LISTAR_RANGO_GIRO']
    },
    {
      name: 'Tipo de Publicidad',
      routeOrFunction: '/webapp/comercio/tipo-publicidad',
      icon: 'group',
      position: 41,
      roles: ['ROLE_LISTAR_TIPO_PUBLICIDAD']
    },
  /*   {
      name: 'Rango Publicidad',
      routeOrFunction: '/webapp/comercio/rango-publicidad',
      icon: 'group',
      position: 42,
      roles: ['ROLE_LISTAR_RANGO_PUBLICIDAD']
    }, */
    {
      name: 'Zona',
      routeOrFunction: '/webapp/comercio/zona',
      icon: 'group',
      position: 45,
      roles: ['ROLE_LISTAR_ZONA_COMERCIO']
    },
    {
      name: 'Sector',
      routeOrFunction: '/webapp/comercio/sector',
      icon: 'group',
      position: 50,
      roles: ['ROLE_LISTAR_SECTOR_COMERCIO']
    },
    {
      name: 'Asentamiento',
      routeOrFunction: '/webapp/comercio/asentamiento',
      icon: 'group',
      position: 55,
      roles: ['ROLE_LISTAR_ASENTAMIENTO_COMERCIO']
    },
    {
      name: 'Tipo Documento',
      routeOrFunction: '/webapp/comercio/tipo-documento',
      icon: 'group',
      position: 56,
      roles: ['ROLE_VER_TIPO_DOCUMENTO']
    },
          ]
    },
   
    {
      name: 'CONFIGURACIÓN',
      position: 200,
      icon: 'settings',
      roles: [
        'ROLE_VER_USUARIOS_AREA_TRAMITE',
        'ROLE_VER_ESTADOS_AREA_TRAMITE',
        'ROLE_VER_CONFIGURACION_DIRECTIVO',
        'ROLE_VER_CONFIGURACION_TRAMITE_COMERCIO',
        'ROLE_VER_TIPO_DOCUMENTO_IGUAL',
        'ROLE_VER_CONFIGURACION_VALIDEZ_DICTAMEN',
        'ROLE_VER_GIRO_RANGO_GIRO_COMERCIO','ROLE_GESTIONAR_CONFIGURACION_GIRO_COMERCIO'
      ],
      subItems: [
        {
          name: 'Config. Giro',
          routeOrFunction: '/webapp/comercio/config-rango-giro',
          icon: 'group',
          position: 10,
          roles: ['ROLE_VER_GIRO_RANGO_GIRO_COMERCIO','ROLE_GESTIONAR_CONFIGURACION_GIRO_COMERCIO']
        },

    {
      name: 'Usuarios/Áreas',
      routeOrFunction: '/webapp/comercio/tramites/configuracion-usuarios',
      icon: 'group',
      position: 505,
      roles: [
        'ROLE_VER_USUARIOS_AREA_TRAMITE',
      ]
    },
    {
      name: 'Área-Estados',
      routeOrFunction: '/webapp/comercio/tramites/configuracion-estados',
      icon: 'group',
      position: 510,
      roles: [
        'ROLE_VER_ESTADOS_AREA_TRAMITE',
      ]
    },
    {
      name: 'Configuración Tramites',
      routeOrFunction: '/webapp/comercio/tramites/configuracion-tramite',
      icon: 'group',
      position: 512,
      roles: [
        'ROLE_VER_CONFIGURACION_TRAMITE_COMERCIO',
      ]
    },
    {
      name: 'Config Doc-General',
      routeOrFunction: '/webapp/comercio/tramites/doc-general',
      icon: 'group',
      position: 513,
      roles: [
        'ROLE_VER_CONFIGURACION_TRAMITE_COMERCIO',
      ]
    },
    {
      name: 'Config Doc-Tipo Persona',
      routeOrFunction: '/webapp/comercio/tramites/doc-tipo-persona',
      icon: 'group',
      position: 516,
      roles: [
        'ROLE_VER_CONFIGURACION_TRAMITE_COMERCIO',
      ]
    },
    {
      name: 'Config Doc-Rango',
      routeOrFunction: '/webapp/comercio/tramites/doc-rango',
      icon: 'group',
      position: 516,
      roles: [
        'ROLE_VER_CONFIGURACION_TRAMITE_COMERCIO',
      ]
    },
    {
      name: 'Config Doc-Giros',
      routeOrFunction: '/webapp/comercio/tramites/doc-giros',
      icon: 'group',
      position: 517,
      roles: [
        'ROLE_VER_CONFIGURACION_TRAMITE_COMERCIO',
      ]
    },
    {
      name: 'Config Doc-Arrendado',
      routeOrFunction: '/webapp/comercio/tramites/doc-arrendado',
      icon: 'group',
      position: 518,
      roles: [
        'ROLE_VER_CONFIGURACION_TRAMITE_COMERCIO',
      ]
    },
    {
      name: 'Configuración General',
      routeOrFunction: '/webapp/comercio/configuracion-general/1',
      icon: 'group',
      position: 520,
      roles: [
        'ROLE_VER_CONFIGURACION_GENERAL',
      ]
    },
        // {
        //   name: 'Config Tipo Doc-Igual',
        //   routeOrFunction: '/webapp/comercio/configuracion-tipo-documento-igual',
        //   icon: 'group',
        //   position: 525,
        //   roles: [
        //     'ROLE_VER_TIPO_DOCUMENTO_IGUAL',
        //   ]
        // },
        {
          name: 'Config Validez Dictamen',
          routeOrFunction: '/webapp/comercio/configuracion-validez-dictamen',
          icon: 'group',
          position: 530,
          roles: [
            'ROLE_VER_CONFIGURACION_VALIDEZ_DICTAMEN',
          ]
        }
    ]
    }
  ]
};
