import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from './layout/layout.component';
import {RouterGuard} from './src/administracion/security-router/router.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'webapp',
        pathMatch: 'full'
    },
    {
        path: 'callback',
        loadChildren: () => import('./src/administracion/call-back-cognito/call-back-cognito.module').then(m => m.CallBackModule),
    },
    {
        path: 'drag-and-drop',
        loadChildren: () => import('./pages/drag-and-drop/drag-and-drop.module').then(m => m.DragAndDropModule)
    },
    {
        path: 'login',
        redirectTo: 'webapp',
        pathMatch: 'full'
        //loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./pages/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    },
    {
        path: 'forgot-password/change/:token',
        loadChildren: () => import('./pages/authentication/register/register.module').then(m => m.RegisterModule),
    },
    {
        path: 'forcing-change-pass',
        loadChildren: () => import('./pages/authentication/forcing-change-pass/forcing-change-pass.module').then(m => m.ForcingChangePassModule),
    },
    {
        path: 'forzar-cambio-credencial',
        loadChildren: () => import('./pages/authentication/forzar-cambio-credencial/forzar-cambio-credencial.module').then(m => m.ForzarCambioCredencialModule),
    },
    {
        path: 'permiso',
        loadChildren: () => import('./src/transito/permiso-circulacion-publica/permiso-circulacion.module').then(m => m.PermisoCirculacionModule)
    },
    {
        path: 'webapp',
        component: LayoutComponent,
        canActivate: [RouterGuard],
        children: [

            //-----ADMINISTRACION---------------------------
            {
                path: 'admin/usuarios',
                loadChildren: () => import('./src/administracion/users/users.module').then(m => m.UsersModule)
            },
            {
                path: 'admin/empleado',
                loadChildren: () => import('./src/administracion/empleado/empleado.module').then(m => m.EmpleadoModule)
            },
            {
                path: 'admin/roles',
                loadChildren: () => import('./src/administracion/roles/roles.module').then(m => m.RolsModule)
            },
            {
                path: 'admin/cargo-empleado',
                loadChildren: () => import('./src/rrhh/cargo-empleado/cargo-empleado.module').then(m => m.CargoEmpleadoModule)
            },
            {
                path: 'admin/horario-trabajo',
                loadChildren: () => import('./src/administracion/horario-trabajo/horario-trabajo.module').then(m => m.HorarioTrabajoModule)
            },
            {
                path: 'admin/exclusion-horario',
                loadChildren: () => import('./src/administracion/exclusion-horario/exclusion-horario.module').then(m => m.ExclusionHorarioModule)
            },
            {
                path: 'admin/rango-hora',
                loadChildren: () => import('./src/administracion/rango-hora/rango-hora.module').then(m => m.RangoHoraModule)
            },
            {
                path: 'admin/clave-valor-pdf',
                loadChildren: () => import('./src/administracion/clave-valor-pdf/clave-valor-pdf.module').then(m => m.ClaveValorPdfModule)
            },
            {
                path: 'admin/patches',
                loadChildren: () => import('./src/administracion/patches/patches.module').then(m => m.PatchesModule)
            },
            {
                path: 'admin/migrar',
                loadChildren: () => import('./src/administracion/migrar/migrar.module').then(m => m.MigrarModule),
            },
            {
                path: 'admin/version',
                loadChildren: () => import('./src/administracion/version-apis/version.module').then(m => m.VersionModule)
            },

            //-----CAJAS---------------------------
            {
                path: 'cajas/cierre-cajero',
                loadChildren: () => import('./src/cajas/cierre-cajero/cierre-cajero.module').then(m => m.CierreCajeroModule)
            },
            {
                path: 'cajas/pago',
                loadChildren: () => import('./src/cajas/pago/pago.module').then(m => m.PagoModule)
            },
            {
                path: 'cajas/panel-control',
                loadChildren: () => import('./src/cajas/panel-control/panel-control.module').then(m => m.PanelControlModule)
            },
            //-----CATASTRO---------------------------
            {
                path: 'catastro/predio',
                loadChildren: () => import('./src/catastro/predio/predio.module').then(m => m.PredioModule)
            },
            {
                path: 'catastro/mantenimiento/predio',
                loadChildren: () => import('./src/catastro/mantenimiento-predio/mantenimiento-predio.module').then(m => m.MantenimientoPredioModule)
            },
            {
                path: 'catastro/valorUnitario',
                loadChildren: () => import('./src/catastro/valor-unitario/valor-unitario.module').then(m => m.ValorUnitarioModule)
            },
            {
                path: 'catastro/manzanaCatastral',
                loadChildren: () => import('./src/catastro/manzana-catastral/manzana-catastral.module').then(m => m.ManzanaCatastralModule)
            },
            {
                path: 'catastro/excavacion',
                loadChildren: () => import('./src/catastro/excavacion/excavacion.module').then(m => m.ExcavacionModule)
            },
            {
                path: 'catastro/tipologiaConstruccion',
                loadChildren: () => import('./src/catastro/tipologia-construccion/tipologia-construccion.module').then(m => m.TipologiaConstruccionModule)
            },
            {
                path: 'catastro/campo/brigada-campo',
                loadChildren: () => import('./src/catastro/campo/brigada-campo/brigada-campo.module').then(m => m.BrigadaCampoModule)
            },
            {
                path: 'catastro/campo/visita',
                loadChildren: () => import('./src/catastro/campo/visita-campo/visita-campo.module').then(m => m.VisitaCampoModule)
            },
            {
                path: 'catastro/campo/horas',
                loadChildren: () => import('./src/catastro/campo/visita-horas/visita-horas.module').then(m => m.VisitaHorasModule)
            },
            {
                path: 'catastro/tipo-derecho-real',
                loadChildren: () => import('./src/catastro/tipo-derecho-real/tipo-derecho-real.module').then(m => m.TipoDerechoRealModule)
            },
            {
                path: 'catastro/tipoEsquina',
                loadChildren: () => import('./src/catastro/tipo-esquina/tipo-esquina.module').then(m => m.TipoEsquinaModule)
            },
            {
                path: 'catastro/uso-suelo',
                loadChildren: () => import('./src/catastro/uso-suelo/uso-suelo.module').then(m => m.UsoSueloModule)
            },
            {
                path: 'catastro/desnivel',
                loadChildren: () => import('./src/catastro/desnivel/desnivel.module').then(m => m.DesnivelModule)
            },
            {
                path: 'catastro/importar',
                loadChildren: () => import('./src/catastro/import/import.module').then(m => m.ImportModule)
            },
            {
                path: 'catastro/validar',
                loadChildren: () => import('./src/catastro/import-validate/import-validate.module').then(m => m.ImportValidateModule)
            },
            {
                path: 'catastro/resultado-validar',
                loadChildren: () => import('./src/catastro/result-validate/result-validate.module').then(m => m.ResultValidateModule)
            },
            {
                path: 'catastro/predio-no-importado',
                loadChildren: () => import('./src/catastro/predio-no-importado/predio-no-importado.module').then(m => m.PredioNoImportadoModule)
            },
            {
                path: 'catastro/proceso-calculo-valor',
                loadChildren: () => import('./src/catastro/proceso-calculo-valor/proceso-calculo-valor.module').then(m => m.ProcesoCalculoValorModule)
            },
            {
                path: 'catastro/config-validacion-predio',
                loadChildren: () => import('./src/catastro/config-proc-validacion/config-proc-validacion.module').then(m => m.ConfigProcValidacionModule)
            },
            {
                path: 'catastro/validacion-predio',
                loadChildren: () => import('./src/catastro/validacion-predio/validacion-predio.module').then(m => m.ValidacionPredioModule)
            },
            {
                path: 'catastro/importar-validacion',
                loadChildren: () => import('./src/catastro/import-validacion/import-validacion.module').then(m => m.ImportValidacion)
            },
            {
                path: 'catastro/proceso-validacion',
                loadChildren: () => import('./src/catastro/proceso-validacion/proceso-validacion.module').then(m => m.ProcesoValidacionModule)
            },
            {
                path: 'catastro/resultado-importar',
                loadChildren: () => import('./src/catastro/result-import/result-import.module').then(m => m.ResultImportModule)
            },
            //-----TESORERIA---------------------------
            {
                path: 'tesoreria/conceptoIngreso',
                loadChildren: () => import('./src/tesoreria/concepto-ingreso/concepto-ingreso.module').then(m => m.ConceptoIngresoModule)
            },
            {
                path: 'tesoreria/rubroIngreso',
                loadChildren: () => import('./src/tesoreria/rubro-ingreso/rubro-ingreso.module').then(m => m.RubroIngresoModule)
            },
            {
                path: 'tesoreria/cuentaAbono',
                loadChildren: () => import('./src/tesoreria/cuenta-abono/cuenta-abono.module').then(m => m.CuentaAbonoModule)
            },
            {
                path: 'tesoreria/cuentaCargo',
                loadChildren: () => import('./src/tesoreria/cuenta-cargo/cuenta-cargo.module').then(m => m.CuentaCargoModule)
            },
            {
                path: 'tesoreria/forma-pago',
                loadChildren: () => import('./src/tesoreria/forma-pago/forma-pago.module').then(m => m.FormaPagoModule)
            },
            {
                path: 'tesoreria/banco',
                loadChildren: () => import('./src/tesoreria/banco/banco.module').then(m => m.BancoModule)
            },
            {
                path: 'tesoreria/forma-pago-banco-emisor',
                loadChildren: () => import('./src/tesoreria/forma-pago-banco-emisor/forma-pago-banco-emisor.module').then(m => m.FormaPagoBancoEmisorModule)
            },
            {
                path: 'tesoreria/tipo-contribucion',
                loadChildren: () => import('./src/tesoreria/tipo-contribucion/tipo-contribucion.module').then(m => m.TipoContribucionModule)
            },
            {
                path: 'tesoreria/descuento',
                loadChildren: () => import('./src/tesoreria/descuento/descuento.module').then(m => m.DescuentoModule)
            },
            {
                path: 'tesoreria/recargo',
                loadChildren: () => import('./src/tesoreria/recargo/recargo.module').then(m => m.RecargoModule)
            },
            {
                path: 'tesoreria/grupoCajeros',
                loadChildren: () => import('./src/tesoreria/grupo-cajeros/grupo-cajeros.module').then(m => m.GrupoCajerosModule)
            },
            {
                path: 'tesoreria/inpc',
                loadChildren: () => import('./src/tesoreria/inpc/inpc.module').then(m => m.InpcModule)
            },
            {
                path: 'tesoreria/uma',
                loadChildren: () => import('./src/tesoreria/uma/uma.module').then(m => m.UmaModule)
            },
            {
                path: 'tesoreria/nota-credito',
                loadChildren: () => import('./src/tesoreria/nota-credito/nota-credito.module').then(m => m.NotaCreditoModule)
            },
            {
                path: 'tesoreria/polizas',
                loadChildren: () => import('./src/tesoreria/poliza/poliza.module').then(m => m.PolizaModule)
            },
            {
                path: 'tesoreria/reportes',
                loadChildren: () => import('./src/tesoreria/reportes/reportes.module').then(m => m.ReportesModule)
            },
            {
                path: 'tesoreria/configuracion',
                loadChildren: () => import('./src/tesoreria/configuracion/configuracion.module').then(m => m.ConfiguracionlModule)
            },
            {
                path: 'tesoreria/orden-pago',
                loadChildren: () => import('./src/tesoreria/orden-pago/orden-pago.module').then(m => m.OrdenPagoModule)
            },
            {
                path: 'tesoreria/multa-transito',
                loadChildren: () => import('./src/tesoreria/multa-transito/multa.module').then(m => m.MultaModule)
            },
            {
                path: 'tesoreria/multa-fiscalizacion',
                loadChildren: () => import('./src/tesoreria/multa-fiscalizacion/multa.module').then(m => m.MultaModule)
            },
            {
                path: 'tesoreria/conf-general',
                loadChildren: () => import('./src/tesoreria/configuracion-general/configuracion-general.module').then(m => m.ConfiguracionGeneralModule)
            },
            {
                path: 'tesoreria/conf-usuario-dependencia',
                loadChildren: () => import('./src/tesoreria/config-usuario-dependencia/config-usuario-dependencia.module').then(m => m.ConfigUsuarioDependenciaModule)
            },
            {
                path: 'tesoreria/config-regla-grupo-cajero',
                loadChildren: () => import('./src/tesoreria/config-regla-grupo-cajero/config-regla-grupo-cajero.module').then(m => m.ConfigReglaGrupoCajeroModule)
            },
            {
                path: 'tesoreria/importar',
                loadChildren: () => import('./src/tesoreria/import/import.module').then(m => m.ImportModule)
            },
            {
                path: 'tesoreria/importar',
                loadChildren: () => import('./src/tesoreria/import/import.module').then(m => m.ImportModule)
            },
            {
                path: 'tesoreria/validar',
                loadChildren: () => import('./src/tesoreria/import-validate/import-validate.module').then(m => m.ImportValidateModule)
            },
            {
                path: 'tesoreria/resultado-validar',
                loadChildren: () => import('./src/tesoreria/result-validate/result-validate.module').then(m => m.ResultValidateModule)
            },
            {
                path: 'tesoreria/resultado-importar',
                loadChildren: () => import('./src/tesoreria/result-import/result-import.module').then(m => m.ResultImportModule)
            },
            {
                path: 'tesoreria/resultado-exportar',
                loadChildren: () => import('./src/tesoreria/result-export/result-export.module').then(m => m.ResultExportModule)
            },
            {
                path: 'tesoreria/import-informacion',
                loadChildren: () => import('./src/tesoreria/import-informacion/import-informacion.module').then(m => m.ImportInformacion)
            },
            {
                path: 'tesoreria/result-import-informacion',
                loadChildren: () => import('./src/tesoreria/result-import-informacion/result-import-informacion.module').then(m => m.ResultImportInformacionModule)
            },
            {
                path: 'tesoreria/informacion',
                loadChildren: () => import('./src/tesoreria/informacion/informacion.module').then(m => m.InformacionModule)
            },
            {
                path: 'tesoreria/conf-informacion',
                loadChildren: () => import('./src/tesoreria/config-informacion/config-informacion.module').then(m => m.ConfigInformacionModule)
            },
            /* {
               path: 'tesoreria/dependencia',
               loadChildren: () => import('./src/rrhh/dependencia/dependencia.module').then(m => m.DependenciaModule)
             },*/
            {
                path: 'tesoreria/orden-pago',
                loadChildren: () => import('./src/tesoreria/orden-pago/orden-pago.module').then(m => m.OrdenPagoModule)
            },
            {
                path: 'tesoreria/filtro1/orden-pago',
                loadChildren: () => import('./src/tesoreria/orden-pago/orden-pago.module').then(m => m.OrdenPagoModule)
            },
            {
                path: 'tesoreria/filtro2/orden-pago',
                loadChildren: () => import('./src/tesoreria/orden-pago/orden-pago.module').then(m => m.OrdenPagoModule)
            },
            {
                path: 'tesoreria/filtro3/orden-pago',
                loadChildren: () => import('./src/tesoreria/orden-pago/orden-pago.module').then(m => m.OrdenPagoModule)
            },
            //-----RECURSOS HUMANOS---------------------------
            /*  {
               path: 'rrhh/cargo-empleado',
               loadChildren: () => import('./src/rrhh/cargo-empleado/cargo-empleado.module').then(m => m.CargoEmpleadoModule)
             },
             {
               path: 'rrhh/dependencia',
               loadChildren: () => import('./src/rrhh/dependencia/dependencia.module').then(m => m.DependenciaModule)
             },
             {
               path: 'rrhh/empleado',
               loadChildren: () => import('./src/administracion/empleado/empleado.module').then(m => m.EmpleadoModule)
             },
             {
               path: 'rrhh/importar',
               loadChildren: () => import('./src/rrhh/import/import.module').then(m => m.ImportModule)
             }, */


            //-----GENERAL-----------------------------------
            {
                path: 'general/codigo-postal',
                loadChildren: () => import('./src/general/inegi/codigo-postal/codigo-postal.module').then(m => m.CodigoPostalModule)
            },
            {
                path: 'general/estado',
                loadChildren: () => import('./src/general/inegi/estado/estado.module').then(m => m.EstadoModule)
            },
            {
                path: 'general/localidad',
                loadChildren: () => import('./src/general/inegi/localidad/localidad.module').then(m => m.LocalidadModule)
            },
            {
                path: 'general/municipio',
                loadChildren: () => import('./src/general/inegi/municipio/municipio.module').then(m => m.MunicipioModule)
            },
            {
                path: 'general/estado-civil',
                loadChildren: () => import('./src/general/persona/estado-civil/estado-civil.module').then(m => m.EstadoCivilModule)
            },
            {
                path: 'general/nivel-academico',
                loadChildren: () => import('./src/general/persona/nivel-academico/nivel-academico.module').then(m => m.NivelAcademicoModule)
            },
            {
                path: 'general/configuracion-general',
                loadChildren: () => import('./src/general/configuracion-general/configuracion-general.module').then(m => m.ConfiguracionGeneralModule)
            },
            {
                path: 'general',
                loadChildren: () => import('./src/general/components/observaciones/observaciones.module').then(m => m.ObservacionesModule)
            },
            {
                path: 'general/importar',
                loadChildren: () => import('./src/general/import/import.module').then(m => m.ImportModule)
            },
            //-----DESARROLLO URBANO---------------------------
            {
                path: 'durbano/asentamiento',
                loadChildren: () => import('./src/durbano/asentamiento/asentamiento.module').then(m => m.AsentamientoModule)
            },
            {
                path: 'durbano/fraccionamiento',
                loadChildren: () => import('./src/durbano/fraccionamiento/fraccionamiento.module').then(m => m.FraccionamientoModule)
            },
            {
                path: 'durbano/numero-oficial-du',
                loadChildren: () => import('./src/durbano/numero-oficial/numero-oficial.module').then(m => m.NumeroOficialDUModule)
            },
            {
                path: 'durbano/privada',
                loadChildren: () => import('./src/durbano/privada/privada.module').then(m => m.PrivadaModule)
            },
            {
                path: 'durbano/tipo-asentamiento',
                loadChildren: () => import('./src/durbano/tipo-asentamiento/tipo-asentamiento.module').then(m => m.TipoAsentamientoModule)
            },
            {
                path: 'durbano/tipo-vialidad',
                loadChildren: () => import('./src/durbano/tipo-vialidad/tipo-vialidad.module').then(m => m.TipoVialidadModule)
            },
            {
                path: 'durbano/vialidad',
                loadChildren: () => import('./src/durbano/vialidad/vialidad.module').then(m => m.VialidadModule)
            },
            {
                path: 'durbano/numero-oficial',
                loadChildren: () => import('./src/durbano/mantenimiento/numero-oficial/numero-oficial.module').then(m => m.NumeroOficialModule)
            },
            {
                path: 'durbano/importar',
                loadChildren: () => import('./src/durbano/import/import.module').then(m => m.ImportModule)
            },
            //-----CONTRIBUYENTE---------------------------------------------------------------------
            {
                path: 'rec/contribuyente',
                loadChildren: () => import('./src/registro-contribuyente/contribuyente/contribuyente.module').then(m => m.ContribuyenteModule)
            },
            {
                path: 'rec/tipo-identificacion',
                loadChildren: () => import('./src/registro-contribuyente/tipo-identificacion/tipo-identificacion.module').then(m => m.TipoIdentificacionModule)
            },
            //-----TRAMITE------------------------------------------------------------------------

            {
                path: 'tramites/configuracion-estados',
                loadChildren: () => import('./src/tramites/area-estado-tramite/area-estado-tramite.module').then(m => m.AreaEstadoTramiteModule)
            },
            {
                path: 'tramites/configuracion-impresion',
                loadChildren: () => import('./src/tramites/configuracion-impresion/configuracion-impresion.module').then(m => m.ConfiguracionImpresionModule)
            },
            {
                path: 'tramites/configuracion-tramite',
                loadChildren: () => import('./src/tramites/config-tramite/config-tramite.module').then(m => m.ConfigTramiteModule)
            },
            {
                path: 'tramites/config-tramite/catastro',
                loadChildren: () => import('./src/tramites/configuracion-tramite/configuracion-tramite.module').then(m => m.ConfiguracionTramiteModule)
            },
            {
                path: 'tramites/configurar-tramite-general',
                loadChildren: () => import('./src/tramites/configuracion-tramite-general/configuracion-tramite-general.module').then(m => m.ConfiguracionTramiteGeneralModule)
            },
            {
                path: 'tramites/modalidad-tramite',
                loadChildren: () => import('./src/tramites/modalidad-tramite/modalidad-tramite.module').then(m => m.ModalidadTramiteModule)
            },
            {
                path: 'tramites/tipo-documento-tramite',
                loadChildren: () => import('./src/tramites/tipo-documento/tipo-documento.module').then(m => m.TipoDocumentoModule)
            },

            {
                path: 'tramites/modalidad-tramite',
                loadChildren: () => import('./src/tramites/modalidad-tramite/modalidad-tramite.module').then(m => m.ModalidadTramiteModule)
            },
            {
                path: 'tramites/panel-control-tramite',
                loadChildren: () => import('./src/tramites/panel-control-tramite/panel-control-tramite.module').then(m => m.PanelControlTramiteModule)
            },
            {
                path: 'tramites/configuracion-usuarios',
                loadChildren: () => import('./src/tramites/usuario-area-tramite/usuario-area-tramite.module').then(m => m.UsuarioAreaTramiteModule)
            },
            {
                path: 'tramites/tramite',
                loadChildren: () => import('./src/tramites/tramite/tramite.module').then(m => m.TramiteModule)
            },
            //-----TRAMITES CATASTRO---------------------------
            {
                path: 'tramites/catastro/alta-por-regimen-en-condominio',
                loadChildren: () => import('./src/tramites/tramites-catastro/tramite-catastro-alta-condominio/tramite-catastro-alta-condominio.module').then(m => m.TramiteCatastroAltaCondominioModule)
            },
            {
                path: 'tramites/catastro/alta-por-fraccionamiento',
                loadChildren: () => import('./src/tramites/tramites-catastro/tramite-catastro-alta-fraccionamiento/tramite-catastro-alta-fraccionamiento.module').then(m => m.TramiteCatastroAltaFraccionamientoModule)
            },
            {
                path: 'tramites/catastro/alta-division-predio',
                loadChildren: () => import('./src/tramites/tramites-catastro/tramite-catastro-division-predio/tramite-catastro-division-predio.module').then(m => m.TramiteCatastroDivisionPredioModule)
            },
            {
                path: 'tramites/catastro/alta-por-fusion',
                loadChildren: () => import('./src/tramites/tramites-catastro/tramite-catastro-fusion-predio/tramite-catastro-fusion-predio.module').then(m => m.TramiteCatastroFusionPredioModule)
            },
            {
                path: 'tramites/catastro/alta-padron-catastral',
                loadChildren: () => import('./src/tramites/tramites-catastro/tramite-catastro-empadronamiento/tramite-catastro-empadronamiento.module').then(m => m.TramiteCatastroEmpadronamientoModule)
            },
            {
                path: 'tramites/catastro/certificacion-medidas-colindancias',
                loadChildren: () => import('./src/tramites/tramites-catastro/tramite-catastro-certificacion-med-col/tramite-catastro-certificacion-med-col.module').then(m => m.TramiteCatastroCertificacionMedColModule)
            },
            {
                path: 'tramites/catastro/rectificacion-nombre-propietario',
                loadChildren: () => import('./src/tramites/tramites-catastro/tramite-catastro-rectificacion-nombre/tramite-catastro-rectificacion-nombre.module').then(m => m.TramiteCatastroRectificacionNombreModule)
            },
            {
                path: 'tramites/catastro/rectificacion-superficie-terreno',
                loadChildren: () => import('./src/tramites/tramites-catastro/tramite-catastro-rectificacion-superficie-terreno/tramite-catastro-rectificacion-superficie-terreno.module').then(m => m.TramiteCatastroRectificacionSuperficieTerrenoModule)
            },
            {
                path: 'tramites/catastro/rectificacion-ubicacion',
                loadChildren: () => import('./src/tramites/tramites-catastro/tramite-catastro-rectificacion-ubicacion/tramite-catastro-rectificacion_ubicacion.module').then(m => m.TramiteCatastroRectificacionUbicacionModule)
            },
            {
                path: 'tramites/catastro/certificado-si-no-propiedad',
                loadChildren: () => import('./src/tramites/tramites-catastro/tramite-catastro-si-no-propiedad/tramite-catastro-si-no-propiedad.module').then(m => m.TramiteCatastroSiNoPropiedadModule)
            },
            {
                path: 'tramites/catastro/avaluo',
                loadChildren: () => import('./src/tramites/tramites-catastro/tramite-catastro-avaluo/tramite-catastro-avaluo.module').then(m => m.TramiteCatastroAvaluoModule)
            },
            {
                path: 'tramites/catastro/traslado-dominio',
                loadChildren: () => import('./src/tramites/tramites-catastro/tramite-catastro-traslado-dominio/tramite-catastro-traslado-dominio.module').then(m => m.TramiteCatastroTrasladoDominioModule)
            },
            {
                path: 'tramites/alta-contribuyente',
                loadChildren: () => import('./src/tramites/tramite-contribuyente/tramite-contribuyente.module').then(m => m.TramiteContribuyenteModule)
            },
            {
                path: 'tramites/actualizar-contribuyente',
                loadChildren: () => import('./src/tramites/tramite-actualizar-contribuyente/tramite-actualizar-contribuyente.module').then(m => m.TramiteActualizarContribuyenteModule)
            },
            {
                path: 'tramites/contribuyente-general',
                loadChildren: () => import('./src/tramites/tramite-contribuyente-general/tramite-contribuyente-general.module').then(m => m.TramiteContribuyenteModule)
            },
            //-----TRANSITO---------------------------
            {
                path: 'transito/configuracion-general',
                loadChildren: () => import('./src/transito/configuracion-general/configuracion-general.module').then(m => m.ConfiguracionGeneralModule)
            },
            {
                path: 'transito/garantias',
                loadChildren: () => import('./src/transito/garantias/garantias.module').then(m => m.GarantiasModule)
            },
            {
                path: 'transito/objeto-retenido',
                loadChildren: () => import('./src/transito/objeto-retenido/objeto-retenido.module').then(m => m.ObjetoRetenidoModule)
            },
            {
                path: 'transito/razon-retencion',
                loadChildren: () => import('./src/transito/categoria-licencia/categoria-licencia.module').then(m => m.CategoriaLicenciaModule)
            },
            {
                path: 'transito/tipo-contacto',
                loadChildren: () => import('./src/transito/tipo-contacto/tipo-contacto.module').then(m => m.TipoContactoModule)
            },
            {
                path: 'transito/marca',
                loadChildren: () => import('./src/transito/marca/marca.module').then(m => m.MarcaModule)
            },
            {
                path: 'transito/color-config',
                loadChildren: () => import('./src/transito/color-config/color-config.module').then(m => m.ColorConfigModule)
            },
            {
                path: 'transito/tipo-permiso',
                loadChildren: () => import('./src/transito/tipo-permiso/tipo-permiso.module').then(m => m.TipoPermisoModule)
            },
            {
                path: 'transito/conceptos',
                loadChildren: () => import('./src/transito/conceptos-transito/conceptos-transito.module').then(m => m.ConceptosTransitoModule)
            },
            {
                path: 'transito/configuracion',
                loadChildren: () => import('./src/transito/configuracion-transito/configuracion-transito.module').then(m => m.ConfiguracionTransitoModule)
            },
            {
                path: 'transito/turno-trabajo',
                loadChildren: () => import('./src/transito/turno-trabajo/turno-trabajo.module').then(m => m.TurnoTrabajoModule)
            },
            {
                path: 'transito/config-dispositivo-oficial',
                loadChildren: () => import('./src/transito/config-dispositivo-oficial/config-dispositivo-oficial.module').then(m => m.ConfigDispositivoOficialModule)
            },

            {
                path: 'transito/multa',
                loadChildren: () => import('./src/transito/multa/multa.module').then(m => m.MultaModule)
            },
            {
                path: 'transito/permiso-circulacion',
                loadChildren: () => import('./src/transito/permiso-circulacion/permiso-circulacion.module').then(m => m.PermisoCirculacionModule)
            },
            {
                path: 'transito/config-permiso',
                loadChildren: () => import('./src/transito/config-permiso/config-permiso.module').then(m => m.ConfigPermisoModule)
            },
            {
                path: 'transito/config-usuario-tipo-permiso',
                loadChildren: () => import('./src/transito/configuracion-usuario-tipo-permiso/configuracion-usuario-tipo-permiso.module').then(m => m.ConfiguracionUsuarioTpoPermisoModule)
            },
            {
                path: 'transito/tipo-documento',
                loadChildren: () => import('./src/transito/tipo-documento/tipo-documento.module').then(m => m.TipoDocumentoModule)
            },
            {
                path: 'transito/tramite-permiso-circulacion-inicio',
                loadChildren: () => import('./src/transito/tramites/tramite-permiso-circulacion-inicio/tramite-permiso-circulacion.module').then(m => m.TramitePermisoCirculacionModule)
            },
            {
                path: 'transito/tramite-permiso-circulacion-refrendo',
                loadChildren: () => import('./src/transito/tramites/tramite-permiso-circulacion-refrendo/tramite-permiso-circulacion.module').then(m => m.TramitePermisoCirculacionModule)
            },
            {
                path: 'transito/tramites/doc-general',
                loadChildren: () => import('./src/transito/config-documento-general/config-documento-general.module').then(m => m.ConfigDocumentoGeneralModule)
            },
            {
                path: 'transito/mapa-multa',
                loadChildren: () => import('./src/transito/mapa/multa-mapa.module').then(m => m.MultaMapaModule)
            },
            {
                path: 'transito/policia',
                loadChildren: () => import('./src/transito/policia/policia.module').then(m => m.PoliciaModule)
            },
            {
                path: 'transito/panel-control',
                loadChildren: () => import('./src/transito/panel-control-transito/panel-control-transito.module').then(m => m.PanelControlTransitoModule)
            },
            //-----COMERCIO---------------------------
            {
                path: 'comercio/mantenimiento/comercio',
                loadChildren: () => import('./src/comercio/comercio/comercio.module').then(m => m.ComercioModule)
            },
            {
                path: 'comercio/comercio',
                loadChildren: () => import('./src/comercio/comercio/comercio.module').then(m => m.ComercioModule)
            },
            {
                path: 'comercio/publicidad',
                loadChildren: () => import('./src/comercio/publicidad-mantenimiento/publicidad.module').then(m => m.PublicidadModule)
            },
            {
                path: 'comercio/mantenimiento/publicidad',
                loadChildren: () => import('./src/comercio/publicidad-mantenimiento/publicidad.module').then(m => m.PublicidadModule)
            },
            {
                path: 'comercio/publicidad',
                loadChildren: () => import('./src/comercio/publicidad-mantenimiento/publicidad.module').then(m => m.PublicidadModule)
            },

            {
                path: 'comercio/giro',
                loadChildren: () => import('./src/comercio/giro/giro.module').then(m => m.GiroModule)
            },
            {
                path: 'comercio/rango-giro',
                loadChildren: () => import('./src/comercio/rango-giro/rango-giro.module').then(m => m.RangoGiroModule)
            },
            {
                path: 'comercio/zona',
                loadChildren: () => import('./src/comercio/zona-comercio/zona-comercio.module').then(m => m.ZonaComercioModule)
            },
            {
                path: 'comercio/sector',
                loadChildren: () => import('./src/comercio/sector-comercio/sector-comercio.module').then(m => m.SectorComercioModule)
            },
            {
                path: 'comercio/asentamiento',
                loadChildren: () => import('./src/comercio/asentamiento-comercio/asentamiento-comercio.module').then(m => m.AsentamientoComercioModule)
            },
            {
                path: 'comercio/tipo-documento',
                loadChildren: () => import('./src/comercio/tipo-documento/tipo-documento.module').then(m => m.TipoDocumentoModule)
            },
            {
                path: 'comercio/configuracion-general',
                loadChildren: () => import('./src/comercio/configuracion-general/configuracion-general.module').then(m => m.ConfiguracionGeneralModule)
            },
            {
                path: 'comercio/tramites/configuracion-tramite',
                loadChildren: () => import('./src/comercio/configuracion-tramite/configuracion-tramite.module').then(m => m.ConfiguracionTramiteModule)
            },
            {
                path: 'comercio/tramites/doc-tipo-persona',
                loadChildren: () => import('./src/comercio/config-documento-tipo-persona/config-documento-tipo-persona.module').then(m => m.ConfigDocumentoTipoPersonaModule)
            },
            {
                path: 'comercio/tramites/doc-giros',
                loadChildren: () => import('./src/comercio/config-documento-giros/config-documento-giros.module').then(m => m.ConfigDocumentoGirosModule)
            },
            {
                path: 'comercio/tramites/doc-rango',
                loadChildren: () => import('./src/comercio/config-documento-rango/config-documento-rango.module').then(m => m.ConfigDocumentoTipoPersonaModule)
            },
            {
                path: 'comercio/tramites/doc-general',
                loadChildren: () => import('./src/comercio/config-documento-general/config-documento-general.module').then(m => m.ConfigDocumentoGeneralModule)
            },
            {
                path: 'comercio/tramites/doc-arrendado',
                loadChildren: () => import('./src/comercio/config-documento-arrendado/config-documento-arrendado.module').then(m => m.ConfigDocumentoArrendadoModule)
            },
            {
                path: 'comercio/tramites/panel-control',
                loadChildren: () => import('./src/comercio/tramites/panel-control-tramite/panel-control-tramite.module').then(m => m.PanelControlTramiteModule)
            },
            {
                path: 'comercio/tramites/general',
                loadChildren: () => import('./src/comercio/tramites/tramite-general/tramite-general.module').then(m => m.TramiteGeneralModule)
            },
            {
                path: 'comercio/tramites/inicio',
                loadChildren: () => import('./src/comercio/tramites/incial/inicial.module').then(m => m.InicialModule)
            },
            {
                path: 'comercio/tramites/refrendo',
                loadChildren: () => import('./src/comercio/tramites/refrendo/refrendo.module').then(m => m.RefrendoModule)
            },
            {
                path: 'comercio/tramites/cambio-direccion',
                loadChildren: () => import('./src/comercio/tramites/cambio-direccion/cambio-direccion.module').then(m => m.CambioDireccionModule)
            },
            {
                path: 'comercio/tramites/cambio-superficie',
                loadChildren: () => import('./src/comercio/tramites/cambio-superficie/cambio-superficie.module').then(m => m.CambioSuperficieModule)
            },
            {
                path: 'comercio/tramites/cambio-giro',
                loadChildren: () => import('./src/comercio/tramites/cambio-giro/cambio-giro.module').then(m => m.CambioGiroModule)
            },
            {
                path: 'comercio/tramites/cambio-razon-social',
                loadChildren: () => import('./src/comercio/tramites/cambio-razon-social/cambio-razon-social.module').then(m => m.CambioRazonSocialModule)
            },
            {
                path: 'comercio/tramites/reposicion',
                loadChildren: () => import('./src/comercio/tramites/reposicion/reposicion.module').then(m => m.ReposicionModule)
            },
            {
                path: 'comercio/tramites/baja',
                loadChildren: () => import('./src/comercio/tramites/baja/baja.module').then(m => m.BajaModule)
            },
            {
                path: 'comercio/tramites/reactivacion',
                loadChildren: () => import('./src/comercio/tramites/reactivacion/reactivacion.module').then(m => m.ReactivacionModule)
            },
            {
                path: 'comercio/tramites/publicidad',
                loadChildren: () => import('./src/comercio/tramites/solicitud-publicidad/solicitud-publicidad.module').then(m => m.SolicitudPublicidadModule)
            },
            {
                path: 'comercio/tramites/baja-publicidad',
                loadChildren: () => import('./src/comercio/tramites/baja-publicidad/baja-publicidad.module').then(m => m.BajaPublicidadModule)
            },
            {
                path: 'comercio/tramites/configuracion-estados',
                loadChildren: () => import('./src/comercio/area-estado-tramite/area-estado-tramite.module').then(m => m.AreaEstadoTramiteModule)
            },
            {
                path: 'comercio/config-rango-giro',
                loadChildren: () => import('./src/comercio/giro-rango-giro-comercio/giro-rango-giro-comercio.module').then(m => m.GiroRangoGiroComercioModule)
            },
            {
                path: 'comercio/tramites/configuracion-usuarios',
                loadChildren: () => import('./src/comercio/usuario-area-tramite/usuario-area-tramite.module').then(m => m.UsuarioAreaTramiteModule)
            },
            {
                path: 'comercio/reporte',
                loadChildren: () => import('./src/comercio/reporte/reporte.module').then(m => m.ReporteModule)
            },
            {
                path: 'comercio/mapa',
                loadChildren: () => import('./src/comercio/mapa/comercio-mapa.module').then(m => m.ComercioMapaModule)
            },
            {
                path: 'comercio/tipo-publicidad',
                loadChildren: () => import('./src/comercio/tipo-publicidad/tipo-publicidad.module').then(m => m.TipoPublicidadModule)
            },
            {
                path: 'comercio/rango-publicidad',
                loadChildren: () => import('./src/comercio/rango-publicidad/rango-publicidad.module').then(m => m.RangoPublicidadModule)
            },
            {
                path: 'comercio/configuracion-tipo-documento-igual',
                loadChildren: () => import('./src/comercio/config-tipo-documento-igual/config-tipo-documento-igual.module').then(m => m.ConfigTipoDocumentoIgualModule)
            },
            {
                path: 'comercio/configuracion-validez-dictamen',
                loadChildren: () => import('./src/comercio/config-validez-dictamen/config-validez-dictamen.module').then(m => m.ConfigValidezDictamenModule)
            },


            //-----ONLINE----------------------------------------------------------------------------------------
            {
                path: 'tramite/configurar-tramite',
                loadChildren: () => import('./src/solicitudes/config-tramite/config-tramite.module').then(m => m.ConfigTramiteModule)
            },
            {
                path: 'tramite/grupo',
                loadChildren: () => import('./src/solicitudes/grupo/grupo.module').then(m => m.GrupoModule)
            },

            {
                path: 'tramite/solicitud-tramite',
                loadChildren: () => import('./src/solicitudes/solicitud-tramite/solicitud-tramite.module').then(m => m.SolicitudTramiteModule)
            },
            //-------------DOCUMENTO----------------------------------------------------------------------------
            {
                path: 'documento/terminos-condiciones',
                loadChildren: () => import('./src/solicitudes/terminos-condiciones/terminos-condiciones.module').then(m => m.TerminosCondicionesModule)
            },
            //-----NOTARIA---------------------------
            {
                path: 'notarias/notaria',
                loadChildren: () => import('./src/notarias/notaria/notaria.module').then(m => m.NotariaModule)
            },
            {
                path: 'notarias/gestores',
                loadChildren: () => import('./src/notarias/gestores/gestores.module').then(m => m.GestoresModule)
            },
            {
                path: 'notarias/gestores-notaria',
                loadChildren: () => import('./src/notarias/gestores-notaria/gestores-notaria.module').then(m => m.GestoresNotariaModule)
            },
            //-----ADMINISTRACION---------------------------
            {
                path: 'admin/usuarios-online',
                loadChildren: () => import('./src/administracion-online/users/users.module').then(m => m.UsersModule)
            },
            {
                path: 'admin/usuarios-notaria',
                loadChildren: () => import('./src/administracion-online/users-notaria/user-notaria.module').then(m => m.UsersNotariaModule)
            },
            {
                path: 'admin/roles-notaria',
                loadChildren: () => import('./src/administracion-online/roles/roles.module').then(m => m.RolsModule)
            },
            {
                path: 'admin/roles-online',
                loadChildren: () => import('./src/administracion-online/roles/roles.module').then(m => m.RolsModule)
            },
            /*  {
               path: 'admin/patches',
               loadChildren: () => import('./src/administracion-online/patches/patches.module').then(m => m.PatchesModule)
             }, */
            {
                path: 'admin/test-mail',
                loadChildren: () => import('./src/administracion-online/test-mail/test-mail.module').then(m => m.TestMailModule)
            },


            //-----PROTECCION CIVIL---------------------------
            {
                path: 'proteccion/rango-giro',
                loadChildren: () => import('./src/proteccion/rango-giro-seguridad/rango-giro-seguridad.module').then(m => m.RangoGiroSeguridadModule)
            },
            {
                path: 'proteccion/config-rango-giro',
                loadChildren: () => import('./src/proteccion/giro-rango-giro-seguridad/giro-rango-giro-seguridad.module').then(m => m.GiroRangoGiroSeguridadModule)
            },
            {
                path: 'proteccion/tramites/panel-control',
                loadChildren: () => import('./src/proteccion/tramites/panel-control-tramite/panel-control-tramite.module').then(m => m.PanelControlTramiteModule)
            },
            {
                path: 'proteccion/dictamen',
                loadChildren: () => import('./src/proteccion/dictamen/dictamen.module').then(m => m.DictamenModule)
            },
            {
                path: 'proteccion/configuracion-general',
                loadChildren: () => import('./src/proteccion/configuracion-general/configuracion-general.module').then(m => m.ConfiguracionGeneralModule)
            },
            {
                path: 'proteccion/tramites/configuracion-estados',
                loadChildren: () => import('./src/proteccion/area-estado-tramite-proteccion/area-estado-tramite-proteccion.module').then(m => m.AreaEstadoTramiteProteccionModule)
            },
            {
                path: 'proteccion/tramites/configuracion-usuarios',
                loadChildren: () => import('./src/proteccion/usuario-area-tramite-proteccion/usuario-area-tramite-proteccion.module').then(m => m.UsuarioAreaTramiteProteccionModule)
            },
            {
                path: 'proteccion/tramites/doc-general',
                loadChildren: () => import('./src/proteccion/config-documento-general/config-documento-general.module').then(m => m.ConfigDocumentoGeneralModule)
            },
            {
                path: 'proteccion/tramites/doc-arrendado',
                loadChildren: () => import('./src/proteccion/config-documento-arrendado/config-documento-arrendado.module').then(m => m.ConfigDocumentoArrendadoModule)
            },
            {
                path: 'proteccion/tipo-documento',
                loadChildren: () => import('./src/proteccion/tipo-documento/tipo-documento.module').then(m => m.TipoDocumentoModule)
            },
            {
                path: 'proteccion/tramites/configuracion-tramite',
                loadChildren: () => import('./src/proteccion/configuracion-tramite/configuracion-tramite.module').then(m => m.ConfiguracionTramiteModule)
            },
            {
                path: 'proteccion/tramites/general',
                loadChildren: () => import('./src/proteccion/tramites/tramite-general/tramite-general.module').then(m => m.TramiteGeneralModule)
            },
            {
                path: 'proteccion/tramites/inicio',
                loadChildren: () => import('./src/proteccion/tramites/incial/inicial.module').then(m => m.InicialModule)
            },
            {
                path: 'proteccion/tramites/refrendo',
                loadChildren: () => import('./src/proteccion/tramites/refrendo/refrendo.module').then(m => m.RefrendoModule)
            },

            //-----ECOLOGÍA---------------------------
            {
                path: 'ecologia/rango-giro',
                loadChildren: () => import('./src/ecologia/rango-giro-seguridad/rango-giro-seguridad.module').then(m => m.RangoGiroSeguridadModule)
            },
            {
                path: 'ecologia/config-rango-giro',
                loadChildren: () => import('./src/ecologia/giro-rango-giro-seguridad/giro-rango-giro-seguridad.module').then(m => m.GiroRangoGiroSeguridadModule)
            },
            {
                path: 'ecologia/tramites/panel-control',
                loadChildren: () => import('./src/ecologia/tramites/panel-control-tramite/panel-control-tramite.module').then(m => m.PanelControlTramiteModule)
            },
            {
                path: 'ecologia/dictamen',
                loadChildren: () => import('./src/ecologia/dictamenEcologia/dictamen.module').then(m => m.DictamenModule)
            },
            {
                path: 'ecologia/configuracion-general',
                loadChildren: () => import('./src/ecologia/configuracion-general/configuracion-general.module').then(m => m.ConfiguracionGeneralModule)
            },
            {
                path: 'ecologia/tramites/configuracion-estados',
                loadChildren: () => import('./src/ecologia/area-estado-tramite-ecologia/area-estado-tramite-ecologia.module').then(m => m.AreaEstadoTramiteEcologiaModule)
            },
            {
                path: 'ecologia/tramites/configuracion-usuarios',
                loadChildren: () => import('./src/ecologia/usuario-area-tramite-ecologia/usuario-area-tramite-ecologia.module').then(m => m.UsuarioAreaTramiteEcologiaModule)
            },
            {
                path: 'ecologia/tramites/doc-general',
                loadChildren: () => import('./src/ecologia/config-documento-general/config-documento-general.module').then(m => m.ConfigDocumentoGeneralModule)
            },
            {
                path: 'ecologia/tramites/doc-arrendado',
                loadChildren: () => import('./src/ecologia/config-documento-arrendado/config-documento-arrendado.module').then(m => m.ConfigDocumentoArrendadoModule)
            },
            {
                path: 'ecologia/tipo-documento',
                loadChildren: () => import('./src/ecologia/tipo-documento/tipo-documento.module').then(m => m.TipoDocumentoModule)
            },
            {
                path: 'ecologia/tramites/configuracion-tramite',
                loadChildren: () => import('./src/ecologia/configuracion-tramite/configuracion-tramite.module').then(m => m.ConfiguracionTramiteModule)
            },
            {
                path: 'ecologia/tramites/general',
                loadChildren: () => import('./src/ecologia/tramites/tramite-general/tramite-general.module').then(m => m.TramiteGeneralModule)
            },
            {
                path: 'ecologia/tramites/inicio',
                loadChildren: () => import('./src/ecologia/tramites/incial/inicial.module').then(m => m.InicialModule)
            },
            {
                path: 'ecologia/tramites/refrendo',
                loadChildren: () => import('./src/ecologia/tramites/refrendo/refrendo.module').then(m => m.RefrendoModule)
            },
            //-----------FISCALIZACION-----------------------------------------------------------------
            {
                path: 'fiscalizacion/conceptos',
                loadChildren: () => import('./src/fiscalizacion/conceptos-fiscalizacion/conceptos-fiscalizacion.module').then(m => m.ConceptosFiscalizacionModule)
            },
            {
                path: 'fiscalizacion/configuracion-general',
                loadChildren: () => import('./src/fiscalizacion/configuracion-general/configuracion-general.module').then(m => m.ConfiguracionGeneralModule)
            },
            {
                path: 'fiscalizacion/conf-usuario-dependencia',
                loadChildren: () => import('./src/fiscalizacion/config-usuario-dependencia/config-usuario-dependencia.module').then(m => m.ConfigUsuarioDependenciaModule)
            },
            {
                path: 'fiscalizacion/multa',
                loadChildren: () => import('./src/fiscalizacion/multa/multa.module').then(m => m.MultaModule)
            },
            {
                path: 'fiscalizacion/notificacion',
                loadChildren: () => import('./src/fiscalizacion/notificacion-fiscalizacion/notificacion-fiscalizacion.module').then(m => m.NotificacionFiscalizacionModule)
            },
            {
                path: 'fiscalizacion/nomina',
                loadChildren: () => import('./src/fiscalizacion/nomina-fiscalizacion/nomina-fiscalizacion.module').then(m => m.NominaFiscalizacionModule)
            },
            {
                path: 'fiscalizacion/cierre',
                loadChildren: () => import('./src/fiscalizacion/cierre-fiscalizacion/cierre-fiscalizacion.module').then(m => m.CierreFiscalizacionModule)
            },
            //-----SERVICIOS MEDICOS-----------------------------------
            {
                path: 'sm/rrhh/titular',
                loadChildren: () => import('./src/sm/rrhh/titular/titular.module').then(m => m.TitularModule)
            },
            {
                path: 'sm/rrhh/beneficiario',
                loadChildren: () => import('./src/sm/rrhh/beneficiario/beneficiario.module').then(m => m.BeneficiarioModule)
            },
            {
                path: 'sm/rrhh/busqueda',
                loadChildren: () => import('./src/sm/rrhh/buscar/busqueda.module').then(m => m.BusquedaModule)
            },
            {
                path: 'sm/admision/consulta',
                loadChildren: () => import('./src/sm/admision/consulta/consulta.module').then(m => m.ConsultaModule)
            },
            {
                path: 'sm/medico/panel-control',
                loadChildren: () => import('./src/sm/medico/panel-control/panel-control.module').then(m => m.PanelControlModule)
            },
            {
                path: 'sm/medico/consultas',
                loadChildren: () => import('./src/sm/medico/consultas/consulta.module').then(m => m.ConsultaModule)
            },
            {
                path: 'sm/medico/incapacidad',
                loadChildren: () => import('./src/sm/medico/incapacidad/incapacidad.module').then(m => m.IncapacidadModule)
            },
            {
                path: 'sm/institucion/consultas',
                loadChildren: () => import('./src/sm/institucion/consultas/consulta.module').then(m => m.ConsultaModule)
            },
            {
                path: 'sm/institucion/farmacia',
                loadChildren: () => import('./src/sm/institucion/farmacia/farmacia.module').then(m => m.FarmaciaModule)
            },
            {
                path: 'sm/institucion/incapacidad',
                loadChildren: () => import('./src/sm/institucion/incapacidad/incapacidad.module').then(m => m.IncapacidadModule)
            },
            {
                path: 'sm/institucion/autorizos',
                loadChildren: () => import('./src/sm/institucion/autorizos/autorizos.module').then(m => m.AutorizosModule)
            },
            {
                path: 'sm/institucion/autorizos-cirugia',
                loadChildren: () => import('./src/sm/institucion/autorizos-cirugia/autorizos-cirugia.module').then(m => m.AutorizosCirugiaModule)
            },
            {
                path: 'sm/institucion/autorizos-especialidad',
                loadChildren: () => import('./src/sm/institucion/autorizos-especialidad/autorizos-especialidad.module').then(m => m.AutorizosEspecialidadModule)
            },
            {
                path: 'sm/institucion/autorizos-laboratorio',
                loadChildren: () => import('./src/sm/institucion/autorizos-laboratorio/autorizos-laboratorio.module').then(m => m.AutorizosLaboratorioModule)
            },
            {
                path: 'sm/institucion/autorizos-estudio',
                loadChildren: () => import('./src/sm/institucion/autorizos-estudio/autorizos-estudio.module').then(m => m.AutorizosEstudioModule)
            },
            {
                path: 'sm/institucion/autorizaciones',
                loadChildren: () => import('./src/sm/institucion/autorizaciones/autorizacion.module').then(m => m.AutorizacionModule)
            },
            {
                path: 'sm/institucion/configuracion/medicamento',
                loadChildren: () => import('./src/sm/institucion/configuracion/medicamento/medicamento.module').then(m => m.MedicamentoModule)
            },
            {
                path: 'sm/institucion/configuracion/diagnostico',
                loadChildren: () => import('./src/sm/institucion/configuracion/diagnostico-consulta/diagnostico-consulta.module').then(m => m.DiagnosticoConsultaModule)
            },
            {
                path: 'sm/institucion/configuracion/area-atencion',
                loadChildren: () => import('./src/sm/institucion/configuracion/area-atencion/area-atencion.module').then(m => m.AreaAtencionModule)
            },
            {
                path: 'sm/institucion/configuracion/servicio',
                loadChildren: () => import('./src/sm/institucion/configuracion/servicio/servicio.module').then(m => m.ServicioModule)
            },
            {
                path: 'sm/institucion/configuracion/tipo-incapacidad',
                loadChildren: () => import('./src/sm/institucion/configuracion/tipo-incapacidad/tipo-incapacidad.module').then(m => m.TipoIncapacidadModule)
            },
            {
                path: 'sm/reportes',
                loadChildren: () => import('./src/sm/reportes/reportes.module').then(m => m.ReportesModule)
            },
            {
                path: 'sm/institucion/configuracion/config-usuario-tipo-autorizo',
                loadChildren: () => import('./src/sm/institucion/configuracion/config-usuario-tipo-autorizo/config-usuario-tipo-autorizo.module').then(m => m.ConfigUsuarioTipoAutorizoModule)
            },
            {
                path: 'sm/institucion/configuracion/laboratorio',
                loadChildren: () => import('./src/sm/institucion/configuracion/laboratorio/laboratorio.module').then(m => m.LaboratorioModule)
            },
            {
                path: 'sm/institucion/configuracion/motivo-cancelacion',
                loadChildren: () => import('./src/sm/institucion/configuracion/motivo-cancelacion/motivo-cancelacion.module').then(m => m.MotivoCancelacionModule)
            },
            {
                path: 'sm/institucion/configuracion/motivo-cancelacion-autorizacion',
                loadChildren: () => import('./src/sm/institucion/configuracion/motivo-cancelacion-autorizacion/motivo-cancelacion-autorizacion.module').then(m => m.MotivoCancelacionAutorizacionModule)
            },
            {
                path: 'sm/institucion/configuracion/laboratorio/horario-trabajo-laboratorio',
                loadChildren: () => import('./src/sm/institucion/configuracion/horario-trabajo-laboratorio/horario-trabajo.module').then(m => m.HorarioTrabajoModule)
            },
            {
                path: 'sm/institucion/configuracion/especialista',
                loadChildren: () => import('./src/sm/institucion/configuracion/especialista/especialista.module').then(m => m.EspecialistaModule)
            },
            {
                path: 'sm/institucion/configuracion/especialidad-medica',
                loadChildren: () => import('./src/sm/institucion/configuracion/especialidad-medica/especialidad-medica.module').then(m => m.EspecialidadMedicaModule)
            },
            //-----CONSULTORIOS------------------------------------------------------------------------
            {
                path: 'consultorio/medico/paciente',
                //   canActivate: [MedicoGuard],
                loadChildren: () => import('./src/consultorio/medico/paciente/paciente.module').then(m => m.PacienteModule)
            },
            {
                path: 'consultorio/medico/panel-control',
                loadChildren: () => import('./src/consultorio/medico/panel-control/panel-control.module').then(m => m.PanelControlModule)
            },
            {
                path: 'consultorio/medico/consultas',
                //   canActivate: [MedicoGuard],
                loadChildren: () => import('./src/consultorio/medico/consultas/consulta.module').then(m => m.ConsultaModule)
            },
            {
                path: 'consultorio/institucion/farmacia',
                loadChildren: () => import('./src/consultorio/institucion/farmacia/farmacia.module').then(m => m.FarmaciaModule)
            },
            {
                path: 'consultorio/institucion/configuracion/consultorio-institucion',
                loadChildren: () => import('./src/consultorio/institucion/configuracion/consultorio-institucion/consultorio-institucion.module').then(m => m.ConsultorioInstitucionModule)
            },
            {
                path: 'consultorio/institucion/configuracion/medicamento',
                loadChildren: () => import('./src/consultorio/institucion/configuracion/medicamento/medicamento.module').then(m => m.MedicamentoModule)
            },
            {
                path: 'consultorio/institucion/configuracion/diagnostico',
                loadChildren: () => import('./src/consultorio/institucion/configuracion/diagnostico-consulta/diagnostico-consulta.module').then(m => m.DiagnosticoConsultaModule)
            },
            {
                path: 'consultorio/institucion/configuracion/motivo-cancelacion',
                loadChildren: () => import('./src/consultorio/institucion/configuracion/motivo-cancelacion/motivo-cancelacion.module').then(m => m.MotivoCancelacionModule)
            },
            {
                path: 'consultorio/institucion/consultas',
                loadChildren: () => import('./src/consultorio/institucion/consultas-institucion/consultas-institucion.module').then(m => m.ConsultasInstitucionModule)
            },
            {
                path: 'consultorio/admision/consulta',
                loadChildren: () => import('./src/consultorio/admision/consulta/consulta.module').then(m => m.ConsultaModule)
            },
            {
                path: 'consultorio/reportes/consultas',
                loadChildren: () => import('./src/consultorio/reportes/reportes.module').then(m => m.ReportesModule)
            },


            //-----CONSULTORIOS------------------------------------------------------------------------
            {   
              path: 'consultorio/medico/paciente',
            //   canActivate: [MedicoGuard],
              loadChildren: () => import('./src/consultorio/medico/paciente/paciente.module').then(m => m.PacienteModule)
            },
            {
              path: 'consultorio/medico/panel-control',
              loadChildren: () => import('./src/consultorio/medico/panel-control/panel-control.module').then(m => m.PanelControlModule)
            },
            {
              path: 'consultorio/medico/consultas',
            //   canActivate: [MedicoGuard],
              loadChildren: () => import('./src/consultorio/medico/consultas/consulta.module').then(m => m.ConsultaModule)
            },
            {
              path: 'consultorio/institucion/farmacia',
              loadChildren: () => import('./src/consultorio/institucion/farmacia/farmacia.module').then(m => m.FarmaciaModule)
            },  
            {
              path: 'consultorio/institucion/configuracion/consultorio-institucion',
              loadChildren: () => import('./src/consultorio/institucion/configuracion/consultorio-institucion/consultorio-institucion.module').then(m => m.ConsultorioInstitucionModule)
            },
            {
              path: 'consultorio/institucion/configuracion/medicamento',
              loadChildren: () => import('./src/consultorio/institucion/configuracion/medicamento/medicamento.module').then(m => m.MedicamentoModule)
            },
            {
                path: 'consultorio/institucion/configuracion/motivo-cancelacion',
                loadChildren: () => import('./src/consultorio/institucion/configuracion/motivo-cancelacion/motivo-cancelacion.module').then(m => m.MotivoCancelacionModule)
            },
            {
              path: 'consultorio/institucion/consultas',
              loadChildren: () => import('./src/consultorio/institucion/consultas-institucion/consultas-institucion.module').then(m => m.ConsultasInstitucionModule)
            },
            {
              path: 'consultorio/admision/consulta',
              loadChildren: () => import('./src/consultorio/admision/consulta/consulta.module').then(m => m.ConsultaModule)
            },
            {
                path: 'consultorio/reportes/consultas',
                loadChildren: () => import('./src/consultorio/reportes/reportes.module').then(m => m.ReportesModule)
            },

            //-----------------------------------------------------------------------------------------
            {
                path: 'icons',
                loadChildren: () => import('./pages/icons/icons.module').then(m => m.IconsModule),
            },
            {
                path: 'maps/google-maps',
                loadChildren: () => import('./pages/maps/google-maps/google-maps.module').then(m => m.GoogleMapsModule),
            },
            /*  {
                path: 'apps/inbox',
                loadChildren: () => import('./pages/apps/inbox/inbox.module').then(m => m.InboxModule),
              },
              {
                path: 'apps/calendar',
                loadChildren: () => import('./pages/apps/calendar/calendar.module').then(m => m.CalendarAppModule),
              },
              {
                path: 'apps/chat',
                loadChildren: () => import('./pages/apps/chat/chat.module').then(m => m.ChatModule),
              },
              {
                path: 'components',
                loadChildren: () => import('./pages/components/components.module').then(m => m.ComponentsModule),
              },
              {
                path: 'forms/form-elements',
                loadChildren: () => import('./pages/forms/form-elements/form-elements.module').then(m => m.FormElementsModule),
              },
              {
                path: 'forms/form-wizard',
                loadChildren: () => import('./pages/forms/form-wizard/form-wizard.module').then(m => m.FormWizardModule),
              },
              {
                path: 'icons',
                loadChildren: () => import('./pages/icons/icons.module').then(m => m.IconsModule),
              },
              {
                path: 'page-layouts',
                loadChildren: () => import('./pages/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule),
              },
              {
                path: 'maps/google-maps',
                loadChildren: () => import('./pages/maps/google-maps/google-maps.module').then(m => m.GoogleMapsModule),
              },
              {
                path: 'tables/all-in-one-table',
                loadChildren: () => import('./pages/tables/all-in-one-table/all-in-one-table.module').then(m => m.AllInOneTableModule),
              },
              {
                path: 'drag-and-drop',
                loadChildren: () => import('./pages/drag-and-drop/drag-and-drop.module').then(m => m.DragAndDropModule)
              },
              {
                path: 'editor',
                loadChildren: () => import('./pages/editor/editor.module').then(m => m.EditorModule),
              },
              {
                path: 'blank',
                loadChildren: () => import('./pages/blank/blank.module').then(m => m.BlankModule),
              },
              {
                path: 'level1/level2/level3/level4/level5',
                loadChildren: () => import('./pages/level5/level5.module').then(m => m.Level5Module),
              },*/
        ]
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabledNonBlocking',
        // preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
