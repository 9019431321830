import { ModuleConfig, ModuleFuryConfig } from '@quiox/global/config/config';

export const transitoMenu: ModuleFuryConfig = {
    name: 'Tránsito',
    menu: [
        {
            name: 'Tránsito',
            position: 1,
            type: 'subheading',
            customClass: 'first-subheading',
            roles: [
                'ROLE_GESTIONAR_OBJETO_RETENIDO',
                'ROLE_GESTIONAR_CATEGORIA_LICENCIA',
                'ROLE_GESTIONAR_TIPO_CONTACTO',
                'ROLE_LISTAR_MULTA',
                'ROLE_LISTAR_POLICIA',
                'ROLE_GESTIONAR_CONFIGURACION_TRANSITO',
                'ROLE_LISTAR_PERMISOS_CIRCULACION',
                'ROLE_LISTAR_TRAMITE_PERMISOS_CIRCULACION',
                'ROLE_LISTAR_MARCA',
                'ROLE_LISTAR_COLOR_MARCA',
                'ROLE_LISTAR_TIPO_DOCUMENTO',
                'ROLE_LISTAR_TIPO_PERMISO_CIRCULACION',
                'ROLE_LISTAR_CONFIGURACION_PERMISO',
                'ROLE_LISTAR_CONFIGURACION_TRAMITE_PERMISO_CIRCULACION',
                'ROLE_LISTAR_CONFIGURACION_USUARIO_TIPO_PERMISO',
                'ROLE_LISTAR_TRAMITE_TRANSITO_INICIO',


            ]
          },
         /*  {
            name: 'Panel de Control',
            routeOrFunction: '/webapp/transito/panel-control',
            icon: 'widgets',
            position: 2,
            roles: [
                'ROLE_LISTAR_MULTA'
            ]
          },
          {
            name: 'Multas',
            routeOrFunction: '/webapp/transito/multa',
            icon: 'feed',
            position: 4,
            roles: [
                'ROLE_LISTAR_MULTA'
            ]
        }, */
        {
            name: 'Permiso Circulación',
            routeOrFunction: '/webapp/transito/permiso-circulacion',
            icon: 'feed',
            position: 5,
            roles: [
                'ROLE_LISTAR_PERMISOS_CIRCULACION'
            ]
        },
       /*  {
            name: 'Garantías',
            routeOrFunction: '/webapp/transito/garantias',
            icon: 'summarize',
            position: 6,
            roles: [
                'ROLE_LISTAR_GARANTIAS_MULTA'
            ]
        },
        {
            name: 'Ubicación Multas',
            routeOrFunction: '/webapp/transito/mapa-multa',
            icon: 'share_location',
            position: 7,
            roles: [
                'ROLE_VER_UBICACION_MULTAS'
            ]
        },
        {
            name: 'Oficiales Viales',
            routeOrFunction: '/webapp/transito/policia',
            icon: 'group',
            position: 12,
            roles: [
                'ROLE_LISTAR_POLICIA'
            ]
        }
        ,   
        {
            name: 'Conceptos Tránsito',
            routeOrFunction: '/webapp/transito/conceptos',
            icon: 'post_add',
            position: 13,
            roles: [
                'ROLE_LISTAR_CONCEPTOS_TRANSITO'
            ]
        }
        ,   
        {
            name: 'Turnos de Trabajo',
            routeOrFunction: '/webapp/transito/turno-trabajo',
            icon: 'lens_blur',
            position: 14,
            roles: [
                'ROLE_LISTAR_TURNO_TRABAJO'
            ]
          },      
        {
          name: 'Config. Dispositivo-Oficial',
          routeOrFunction: '/webapp/transito/config-dispositivo-oficial',
          icon: 'security_update_good',
          position: 15,
          roles: [
              'ROLE_GESTIONAR_CONFIGURACION_DISPOSITIVO_OFICIAL'
          ]
        }, 
		  ,     
          {
            name: 'Configuración',
            routeOrFunction: '/webapp/transito/configuracion/1',
            icon: 'settings',
            position: 18,
            roles: [
                'ROLE_GESTIONAR_CONFIGURACION_TRANSITO'
            ]
        },*/ 
        {
            name: 'TRÁMITES',
            position: 19,
             icon: 'receipt_long',
            roles: [
              'ROLE_LISTAR_TRAMITE_TRANSITO_INICIO',
              'ROLE_LISTAR_TRAMITE_TRANSITO_REFRENDO'
            ],
             subItems: [   
          {
            name: 'Permiso de Circulación Inicio',
            routeOrFunction: '/webapp/transito/tramite-permiso-circulacion-inicio',
            icon: 'group',
            position: 18,
            roles: ['ROLE_LISTAR_TRAMITE_TRANSITO_INICIO']
          },
          {
            name: 'Permiso de Circulación Refrendo',
            routeOrFunction: '/webapp/transito/tramite-permiso-circulacion-refrendo',
            icon: 'group',
            position: 19,
            roles: ['ROLE_LISTAR_TRAMITE_TRANSITO_REFRENDO']
          }
        ]
        },
        {
            name: 'CATÁLOGO',
            position: 20,
            icon: 'auto_stories',
            roles: [
                'ROLE_GESTIONAR_OBJETO_RETENIDO',
                'ROLE_GESTIONAR_CATEGORIA_LICENCIA',
                'ROLE_GESTIONAR_TIPO_CONTACTO',
                'ROLE_LISTAR_MARCA',
                'ROLE_LISTAR_COLOR_MARCA',
                'ROLE_LISTAR_TIPO_PERMISO_CIRCULACION',
                'ROLE_LISTAR_TIPO_DOCUMENTO_TRANSITO',
            ],
            subItems: [
         /*  {
            name: 'Objeto Retenido',
            routeOrFunction: '/webapp/transito/objeto-retenido',
            icon: 'group',
            position: 22,
            roles: [
                'ROLE_GESTIONAR_OBJETO_RETENIDO'
            ]
        },
        {
            name: 'Razón de Retención',
            routeOrFunction: '/webapp/transito/razon-retencion',
            icon: 'group',
            position: 30,
            roles: [
                'ROLE_GESTIONAR_RAZON_RETENCION'
            ]
        },
        {
            name: 'Tipo Contacto',
            routeOrFunction: '/webapp/transito/tipo-contacto',
            icon: 'group',
            position: 40,
            roles: [
                'ROLE_GESTIONAR_TIPO_CONTACTO'
            ]
        }, */
        {
            name: 'Marca',
            routeOrFunction: '/webapp/transito/marca',
            icon: 'group',
            position: 41,
            roles: [
                'ROLE_LISTAR_MARCA'
            ]
        },
        {
            name: 'Color',
            routeOrFunction: '/webapp/transito/color-config',
            icon: 'group',
            position: 42,
            roles: [
                'ROLE_LISTAR_COLOR_MARCA'
            ]
        },
        {
            name: 'Tipo de Permiso',
            routeOrFunction: '/webapp/transito/tipo-permiso',
            icon: 'group',
            position: 43,
            roles: [
                'ROLE_LISTAR_TIPO_PERMISO_CIRCULACION'
            ]
        },
        {
            name: 'Tipo De Documento',
            routeOrFunction: '/webapp/transito/tipo-documento',
            icon: 'group',
            position: 44,
            roles: [
                'ROLE_LISTAR_TIPO_DOCUMENTO_TRANSITO'
            ]
        },
       
      ]
        },
        {
            name: 'CONFIGURACIÓN',
            position: 200,
            icon: 'settings',
            roles: [
              'ROLE_LISTAR_CONFIGURACION_TRAMITE_PERMISO_CIRCULACION',
              'ROLE_LISTAR_CONFIGURACION_PERMISO',
              'ROLE_LISTAR_CONFIGURACION_USUARIO_TIPO_PERMISO',
            ],
            subItems: [
          {
                    name: 'Configuración Director',
                    routeOrFunction: '/webapp/transito/configuracion-general',
                    icon: 'group',
                    position: 201,
                    roles: [
                      'ROLE_GESTIONAR_CONFIGURACION_GENERAL_TRANSITO',
                    ]
           },
          {
            name: 'Config Permiso - Doc',
            routeOrFunction: '/webapp/transito/tramites/doc-general',
            icon: 'group',
            position: 513,
            roles: [
              'ROLE_LISTAR_CONFIGURACION_TRAMITE_PERMISO_CIRCULACION',
            ]
          },
          {
            name: 'Config Permiso',
            routeOrFunction: '/webapp/transito/config-permiso/1',
            icon: 'group',
            position: 512,
            roles: [
              'ROLE_LISTAR_CONFIGURACION_PERMISO',
            ]
          },
          {
            name: 'Config Permiso - Usuario',
            routeOrFunction: '/webapp/transito/config-usuario-tipo-permiso',
            icon: 'group',
            position: 514,
            roles: [
              'ROLE_LISTAR_CONFIGURACION_USUARIO_TIPO_PERMISO',
            ]
          },
          ]
          }
    ]
};
