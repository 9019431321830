import { ModuleConfig, ModuleFuryConfig } from "../../../global/config/config";

export const notariasMenu: ModuleFuryConfig = { 
    name: 'Notarias',
    menu: [
        {
            name: 'Notaria',
            position: 51,
            type: 'subheading',
            customClass: 'first-subheading',
            roles: [
                'ROLE_GESTIONAR_NOTARIA',
                'ROLE_VER_NOTARIA',
                'ROLE_GESTIONAR_GESTORES_NOTARIA',
                'ROLE_VER_GESTORES_NOTARIA'
            ]
        },
                {
                    name: 'Notaria',
                    routeOrFunction: '/webapp/notarias/notaria',
                    position: 60,
                    icon: 'edit_note',
                    roles: ['ROLE_MASTER', 'ROLE_GESTIONAR_NOTARIA', 'ROLE_VER_NOTARIA']
                },
            ]
};
