import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppUtils } from '@global/helpers/app.utils';
import { SnackBarService } from '@global/helpers/snack-bar.service';
import * as moment from 'moment';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { APP_CONFIG } from 'src/app/src/app.config';




@Injectable({
    providedIn: 'root'
  })
export class DefaultOAuthInterceptor implements HttpInterceptor {

    constructor(private snackBarService: SnackBarService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let url = request.url
        //.toLowerCase();
        //console.log("interceptor", url);

        let bandera = false;

        if (url.includes('api/user/me') || url.includes('oauth') || url.includes('app-info') || url.includes('assets') || url.includes('permiso-circulacion-publico')) {
            bandera = true;
        } else {
            // bandera = this.comprobarHorario();
            bandera = true;
        }


        if (bandera) {
            let secure = true;

            if (!url.startsWith("/")) {
                url = '/' + url;
            }

            if (url.includes('oauth') || url.includes('app-info') || url.includes('assets') || url.includes('permiso-circulacion-publico')) {
                secure = false;
            }

         const config = APP_CONFIG[APP_CONFIG.active_profile];
          const prefix = config.url_gateway;
            if (url.includes('api/dashboard-data') || url.includes('api/invoice-data') || url.includes('api/notifications-data')) {

            } else if (!url.includes(prefix)) {
                //console.log("agregarndo url", url)
                request = request.clone({
                     url: prefix + url ,
                     headers: new HttpHeaders()
                    });
               // request = request.clone({ url: `https://r04kuphoih.execute-api.us-east-1.amazonaws.com/test${url}` });
            }


            return of(secure)
                .pipe(
                    switchMap(secure => secure === true ? from(this.getCurrentSession()) : of(false)),
                    map((auth: any) => this.checkOAuthToken(auth, request)), // switchMap() is used instead of map().
                    map(req => this.checkCaptcha(req)),
                    switchMap(req => next.handle(req)),
                    catchError(err => {
                        //console.log("interceptor errrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",err)
                        this.snackBarService.verification(err);
                        return throwError(err);
                    })
                );

            /* return next
             .handle(request)
             .pipe(
                  catchError(( err:HttpErrorResponse) => {          
                     return throwError(()=>err);
                 }
                 ));
         */
        }
        else {
            this.router.navigateByUrl("login").then(res => {
                this.snackBarService.error("La sesión se ha cerrado porque está trabajando fuera del horario de trabajo")
            })
        }
    }




    private getCurrentSession(): Promise<any> {
        return new Promise((resolve, reject) => {
           /* Auth.currentSession().then(
                session => resolve(session),
                reason => {
                    console.log('Error obteniendo sesion:', reason);
                    reject({ code: 'no_sess', text: reason });
                }
            );*/
            resolve(AppUtils.load("access_token")) 
        });
    }

    private checkOAuthToken(auth, request): HttpRequest<any> {
        // console.log('Auth:', auth);
        if (auth !== false) {
          //  const jwt = auth.accessToken.jwtToken;
          const jwt =auth;
            const with_auth_request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${jwt}`
                }
            });
            // console.log("Cloned", with_auth_request);
            return with_auth_request;
        }
        return request;
    }

    private checkCaptcha(request: HttpRequest<any>): HttpRequest<any> {
        if (AppUtils.getActionName() != null && AppUtils.getRecapchaV3Token() != null) {
            let actionNAme = AppUtils.getActionName();
            let recapchaV3Token = AppUtils.getRecapchaV3Token();

            let headers = request.headers;
            headers = headers.set('Recaptchav3-Token', recapchaV3Token);
            headers = headers.set('Action-Name', actionNAme);
            request = request.clone({ headers });

            AppUtils.delActionName();
            AppUtils.delRecapchaV3Token();
        }

        return request;
    }

    comprobarHorario(): boolean {

        if (AppUtils.currentUser().horario) {
            let horario = AppUtils.currentUser().horario;
            //console.log("horario", horario);

            if (!horario.activado) {
                return true;
            } else {
                if (horario.dias && horario.dias.length > 0) {
                    var dias = horario.dias;
                    var now = moment();
                    var nowFormat = moment().format('HH:mm');
                    var day = now.day();
                    var hourNow = moment(nowFormat, 'HH:mm');

                    //console.log("dias", dias);
                    //console.log("day", day);
                    //console.log("hourNow", hourNow);

                    for (let index = 0; index < dias.length; index++) {
                        const d = dias[index];
                        if (d.diaValor == day) {
                            var rangoHoras = d.rangoHoras;
                            console.log("rangoHoras", rangoHoras);
                            for (let index = 0; index < rangoHoras.length; index++) {
                                const h = rangoHoras[index];
                                var horaInicio = moment(h.horaInicio, 'HH:mm');
                                var horaFin = moment(h.horaFin, 'HH:mm');

                                //console.log("horaInicio", horaInicio);
                                //console.log("horaFin", horaFin);

                                //console.log("hourNow >= horaInicio", hourNow >= horaInicio);

                               //console.log("hourNow < horaFin", hourNow < horaFin);


                                if (hourNow >= horaInicio && hourNow < horaFin) {
                                    //console.log("entro");
                                    return true;
                                }
                            }
                        }
                    }
                    return false;
                }
                else {
                    return false;
                }
            }
        } else {
            return true;
        }
    }

}


