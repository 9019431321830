import {Injectable} from '@angular/core';
import {AppUtils} from '../helpers/app.utils';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {NavigationService} from './navigation.service';


@Injectable({
    providedIn: 'root'
  })
export class AuthenticationService {
    public synced: boolean;

    constructor(private http: HttpClient, private navigation: NavigationService) {
    }

    //  login(username: string, password: string) {
    //     this.logout();

    //     let headers = new HttpHeaders();
    //     headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    //     const body = `username=${username}&password=${password}`;

    //     const authUrl = environment.production ? 'webapp/login' : 'api/login';
    //     return this.http.post(authUrl, body, {headers: headers});

    // return this.http.get(config.urls.base_auth_url, {headers: headers});
    //  }

    login(username: string, password: string) {

        this.synced = false;
        const oauthCredentials: any = AppUtils.getOauthCredential();

        let headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Basic ' + btoa(oauthCredentials.key + ':' + oauthCredentials.secret));
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        const body = new HttpParams()
            .set('username', username)
            .set('password', password)
            .set('grant_type', 'password');
        // const body = `grant_type=password&username=${username}&password=${password}`;            ''
        return this.http.post('/oauth/token', body, {headers: headers});
        // return this.http.get(config.urls.base_auth_url, {headers: headers});
    }

    checkUser(username: string) {

        this.synced = false;
        const oauthCredentials: any = AppUtils.getOauthCredential();

        let headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Basic ' + btoa(oauthCredentials.key + ':' + oauthCredentials.secret));
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        const body = new HttpParams()
            .set('username', username)
            .set('grant_type', 'password');
        return this.http.post('/oauth/token', body, {headers: headers});
    }

    loadSession() {
        AppUtils.sessionStatusSubject.next('live');
        return this.http.get('api/user/me');
    }

    sync(data: any = {}) {
        this.synced = true;
        const user = AppUtils.load('currentUser');
    }

    logout() {
        this.navigation.cleanMenuItem();
        AppUtils.logout();
        this.synced = false;
    }

    public appInfo(): Observable<any> {
        return this.http.get('api/oauth/app-info');
    }

    public apiOauthInfo(): Observable<any> {
        return this.http.get('api/oauth/app-info');
    }

    public apiAdminInfo(): Observable<any> {
        return this.http.get('api/administracion/app-info');
    }

    public apiTesoreriaInfo(): Observable<any> {
        return this.http.get('api/tesoreria/app-info');
    }

    public apiComercioInfo(): Observable<any> {
        return this.http.get('api/comercio/app-info');
    }

    public apiTransitoInfo(): Observable<any> {
        return this.http.get('api/transito/app-info');
    }

    public apiProteccionInfo(): Observable<any> {
        return this.http.get('api/proteccion/app-info');
    }

    public apiEcologiaInfo(): Observable<any> {
        return this.http.get('api/ecologia/app-info');
    }

    public apiFiscalizacionInfo(): Observable<any> {
        return this.http.get('api/fiscalizacion/app-info');
    }

    public apiServicioMedicoInfo(): Observable<any> {
        return this.http.get('api/sm/app-info');
    }

    public apiConsultorioInfo(): Observable<any> {
        return this.http.get('api/consultorio/app-info');
    }

    public logoutOauth(): Observable<any> {
        return this.http.post('api/session/logout', null);
    }

}
