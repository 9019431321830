import {ModuleConfig, ModuleFuryConfig} from '@quiox/global/config/config';

export const smMenu: ModuleFuryConfig = {
    name: 'Servicios Médicos',
    menu: [
        {
            name: 'Servicios Médicos',
            position: 1,
            type: 'subheading',
            customClass: 'first-subheading',
            roles: [
                'ROLE_MASTER',
                'ROLE_VER_TITULAR',
                'ROLE_VER_BENEFICIARIO',
                'ROLE_VER_CONSULTA',
                'ROLE_GESTIONAR_MEDICO_PANEL_CONTROL',
                'ROLE_VER_CONSULTA_MEDICO',
                'ROLE_VER_INCAPACIDAD_MEDICO',
                'ROLE_VER_CONSULTA_INSTITUCION',
                'ROLE_VER_INCAPACIDAD',
                'ROLE_VER_FARMACIA',
                'ROLE_VER_MEDICAMENTO',
                'ROLE_GESTIONAR_MEDICAMENTO',
                'ROLE_VER_AREA_ATENCION',
                'ROLE_GESTIONAR_AREA_ATENCION',
                'ROLE_VER_SERVICIO',
                'ROLE_GESTIONAR_SERVICIO',
                'ROLE_VER_TIPO_INCAPACIDAD',
                'ROLE_GESTIONAR_TIPO_INCAPACIDAD',
                'ROLE_BUSCAR_TITULAR'
            ]
        },
        {
            name: 'RECURSOS HUMANOS',
            position: 2,
            icon: 'group',
            roles: [
                'ROLE_MASTER',
                'ROLE_VER_TITULAR',
                'ROLE_VER_BENEFICIARIO'
            ],
            subItems: [
                {
                    name: 'Titulares',
                    routeOrFunction: '/webapp/sm/rrhh/titular',
                    icon: 'group',
                    position: 21,
                    roles: [
                        'ROLE_MASTER',
                        'ROLE_VER_TITULAR'
                    ]
                },
                {
                    name: 'Beneficiarios',
                    routeOrFunction: '/webapp/sm/rrhh/beneficiario',
                    icon: 'group',
                    position: 22,
                    roles: [
                        'ROLE_MASTER',
                        'ROLE_VER_BENEFICIARIO'
                    ]
                },
                {
                    name: 'Búsqueda',
                    routeOrFunction: '/webapp/sm/rrhh/busqueda',
                    icon: 'group',
                    position: 23,
                    roles: [
                        'ROLE_MASTER',
                        'ROLE_BUSCAR_TITULAR'
                    ]
                },
            ]
        },
        {
            name: 'ADMINISTRACIÓN',
            position: 3,
            icon: 'medical_information',
            roles: [
                'ROLE_MASTER',
                'ROLE_VER_CONSULTA'
            ],
            subItems: [
                {
                    name: 'Consultas',
                    routeOrFunction: '/webapp/sm/admision/consulta',
                    icon: 'medical_services',
                    position: 31,
                    roles: [
                        'ROLE_MASTER',
                        'ROLE_VER_CONSULTA'
                    ]
                }
            ]
        },
        {
            name: 'MÉDICO',
            position: 4,
            icon: 'medical_services',
            roles: [
                'ROLE_MASTER',
                'ROLE_VER_CONSULTA_MEDICO'
            ],
            subItems: [
                {
                    name: 'Consultas',
                    routeOrFunction: '/webapp/sm/medico/consultas',
                    icon: 'medical_services',
                    position: 41,
                    roles: [
                        'ROLE_MASTER',
                        'ROLE_VER_CONSULTA_MEDICO'
                    ]
                }
            ]
        },
        {
            name: 'INSTITUCIÓN MÉDICA',
            position: 5,
            icon: 'account_balance',
            roles: [
                'ROLE_MASTER',
                'ROLE_VER_CONSULTA_INSTITUCION',
                'ROLE_VER_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                'ROLE_LISTAR_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                'ROLE_GESTIONAR_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                'ROLE_VER_INCAPACIDAD_INSTITUCION',
                'ROLE_VER_FARMACIA'
            ],
            subItems: [
                {
                    name: 'Consultas',
                    routeOrFunction: '/webapp/sm/institucion/consultas',
                    icon: 'medical_services',
                    position: 51,
                    roles: ['ROLE_MASTER', 'ROLE_VER_CONSULTA_INSTITUCION']
                },
                {
                    name: 'Todas las Autorizaciones',
                    routeOrFunction: '/webapp/sm/institucion/autorizos',
                    icon: 'security',
                    position: 52,
                    roles: [
                        'ROLE_MASTER',
                        'ROLE_VER_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                        'ROLE_LISTAR_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                        'ROLE_GESTIONAR_AUTORIZOS_DIAGNOSTICO_CONSULTA'
                    ]
                },
                {
                    name: 'Autorizaciones de Cirugía',
                    routeOrFunction: '/webapp/sm/institucion/autorizos-cirugia',
                    icon: 'security',
                    position: 53,
                    roles: [
                        'ROLE_MASTER',
                        'ROLE_VER_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                        'ROLE_LISTAR_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                        'ROLE_GESTIONAR_AUTORIZOS_DIAGNOSTICO_CONSULTA'
                    ]
                },
                {
                    name: 'Autorizaciones de Especialidad',
                    routeOrFunction: '/webapp/sm/institucion/autorizos-especialidad',
                    icon: 'security',
                    position: 54,
                    roles: [
                        'ROLE_MASTER',
                        'ROLE_VER_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                        'ROLE_LISTAR_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                        'ROLE_GESTIONAR_AUTORIZOS_DIAGNOSTICO_CONSULTA'
                    ]
                },
                {
                    name: 'Autorizaciones de Laboratorio',
                    routeOrFunction: '/webapp/sm/institucion/autorizos-laboratorio',
                    icon: 'security',
                    position: 55,
                    roles: [
                        'ROLE_MASTER',
                        'ROLE_VER_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                        'ROLE_LISTAR_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                        'ROLE_GESTIONAR_AUTORIZOS_DIAGNOSTICO_CONSULTA'
                    ]
                },
                {
                    name: 'Autorizaciones de Estudio',
                    routeOrFunction: '/webapp/sm/institucion/autorizos-estudio',
                    icon: 'security',
                    position: 56,
                    roles: [
                        'ROLE_MASTER',
                        'ROLE_VER_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                        'ROLE_LISTAR_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                        'ROLE_GESTIONAR_AUTORIZOS_DIAGNOSTICO_CONSULTA'
                    ]
                },
                {
                    name: 'Autorizaciones de Incapacidad',
                    routeOrFunction: '/webapp/sm/institucion/incapacidad',
                    icon: 'medical_information',
                    position: 57,
                    roles: ['ROLE_MASTER', 'ROLE_VER_INCAPACIDAD_INSTITUCION']
                },
                {
                    name: 'Farmacia',
                    routeOrFunction: '/webapp/sm/institucion/farmacia',
                    icon: 'medication',
                    position: 58,
                    roles: ['ROLE_MASTER', 'ROLE_VER_FARMACIA']
                },
                {
                    name: 'Solicitudes de Autorizaciones',
                    routeOrFunction: '/webapp/sm/institucion/autorizaciones',
                    icon: 'security',
                    position: 59,
                    roles: [
                        'ROLE_MASTER',
                        'ROLE_LISTAR_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                        'ROLE_GESTIONAR_AUTORIZOS_DIAGNOSTICO_CONSULTA'
                    ]
                },
            ]
        },
        {
            name: 'REPORTES',
            position: 10,
            icon: 'grading',
            roles: [
                'ROLE_IMPRIMIR_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                'ROLE_IMPRIMIR_AUTORIZOS_INCAPACIDAD_DIAGNOSTICO_CONSULTA',
            ],
            subItems: [
                {
                    name: 'Consultas',
                    routeOrFunction: '/webapp/sm/reportes/consultas',
                    position: 104,
                    roles: ['ROLE_IMPRIMIR_CONSULTA']
                },
                {
                    name: 'Autorizaciones',
                    routeOrFunction: '/webapp/sm/reportes/autorizos',
                    position: 105,
                    roles: ['ROLE_IMPRIMIR_AUTORIZOS_DIAGNOSTICO_CONSULTA']
                },
                {
                    name: 'Incapacidades',
                    routeOrFunction: '/webapp/sm/reportes/incapacidades',
                    position: 106,
                    roles: ['ROLE_IMPRIMIR_AUTORIZOS_DIAGNOSTICO_CONSULTA',
                        'ROLE_IMPRIMIR_AUTORIZOS_INCAPACIDAD_DIAGNOSTICO_CONSULTA']
                }
            ]
        },
        {
            name: 'CONFIGURACIÓN',
            position: 11,
            icon: 'settings',
            roles: [
                'ROLE_MASTER',
                'ROLE_VER_MEDICAMENTO',
                'ROLE_GESTIONAR_MEDICAMENTO',
                'ROLE_VER_AREA_ATENCION',
                'ROLE_GESTIONAR_AREA_ATENCION',
                'ROLE_VER_TIPO_INCAPACIDAD',
                'ROLE_GESTIONAR_TIPO_INCAPACIDAD',
                'ROLE_VER_LABORATORIO',
                'ROLE_GESTIONAR_LABORATORIO',
                'ROLE_VER_MOTIVO_CANCELACION',
                'ROLE_GESTIONAR_MOTIVO_CANCELACION',
                'ROLE_VER_MOTIVO_CANCELACION_AUTORIZACION',
                'ROLE_GESTIONAR_MOTIVO_CANCELACION_AUTORIZACION',
                'ROLE_VER_CONFIGURACION_USUARIO_AUTORIZO',
                'ROLE_GESTIONAR_CONFIGURACION_USUARIO_AUTORIZO'
            ],
            subItems: [
                {
                    name: 'Medicamentos',
                    routeOrFunction: '/webapp/sm/institucion/configuracion/medicamento',
                    position: 111,
                    roles: [
                        'ROLE_VER_MEDICAMENTO',
                        'ROLE_GESTIONAR_MEDICAMENTO'
                    ]
                },
                {
                    name: 'Áreas de Atención',
                    routeOrFunction: '/webapp/sm/institucion/configuracion/area-atencion',
                    position: 112,
                    roles: [
                        'ROLE_VER_AREA_ATENCION',
                        'ROLE_GESTIONAR_AREA_ATENCION'
                    ]
                },
                {
                    name: 'Diagnóstico',
                    routeOrFunction: '/webapp/sm/institucion/configuracion/diagnostico',
                    position: 113,
                    roles: [
                        'ROLE_VER_DIAGNOSTICO_CONSULTA',
                        'ROLE_GESTIONAR_DIAGNOSTICO_CONSULTA'
                    ]
                },
                {
                    name: 'Tipo Incapacidad',
                    routeOrFunction: '/webapp/sm/institucion/configuracion/tipo-incapacidad',
                    position: 114,
                    roles: [
                        'ROLE_VER_TIPO_INCAPACIDAD',
                        'ROLE_GESTIONAR_TIPO_INCAPACIDAD'
                    ]
                },
                {
                    name: 'Config Usuario-Tipo Autorizo',
                    routeOrFunction: '/webapp/sm/institucion/configuracion/config-usuario-tipo-autorizo',
                    position: 115,
                    roles: [
                        'ROLE_VER_CONFIGURACION_USUARIO_AUTORIZO',
                        'ROLE_GESTIONAR_CONFIGURACION_USUARIO_AUTORIZO'
                    ]
                },
                {
                    name: 'Laboratorios',
                    routeOrFunction: '/webapp/sm/institucion/configuracion/laboratorio',
                    position: 116,
                    roles: [
                        'ROLE_VER_LABORATORIO',
                        'ROLE_GESTIONAR_LABORATORIO'
                    ]
                },
                {
                    name: 'Motivo Canc. Consulta',
                    routeOrFunction: '/webapp/sm/institucion/configuracion/motivo-cancelacion',
                    position: 117,
                    roles: [
                        'ROLE_VER_MOTIVO_CANCELACION',
                        'ROLE_GESTIONAR_MOTIVO_CANCELACION'
                    ]
                },
                {
                    name: 'Motivo Canc. Autorizo',
                    routeOrFunction: '/webapp/sm/institucion/configuracion/motivo-cancelacion-autorizacion',
                    position: 117,
                    roles: [
                        'ROLE_VER_MOTIVO_CANCELACION_AUTORIZACION',
                        'ROLE_GESTIONAR_MOTIVO_CANCELACION_AUTORIZACION'
                    ]
                },
                {
                    name: 'Especialistas',
                    routeOrFunction: '/webapp/sm/institucion/configuracion/especialista',
                    position: 118,
                    roles: [
                        'ROLE_VER_ESPECIALISTA',
                        'ROLE_GESTIONAR_ESPECIALISTA'
                    ]
                },
                {
                    name: 'Especialidades Médicas',
                    routeOrFunction: '/webapp/sm/institucion/configuracion/especialidad-medica',
                    position: 119,
                    roles: [
                        'ROLE_VER_ESPECIALIDAD_MEDICA',
                        'ROLE_GESTIONAR_ESPECIALIDAD_MEDICA'
                    ]
                },
            ]
        }
    ]
};
